import { type MenuProps } from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Space from 'antd/es/space';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { setCurrentLanguage } from '../../store/slices/languageSlice';

const items: MenuProps['items'] = [
  {
    label: <span className="mx-4 inline-flex items-center justify-center">Français</span>,
    icon: <span className="inline-flex items-center justify-center">🇫🇷</span>,
    key: 'fr',
  },
  {
    label: <span className="mx-4 inline-flex items-center justify-center">English</span>,
    icon: <span className="inline-flex items-center justify-center">🇬🇧</span>,
    key: 'en',
    disabled: !true,
  },
  {
    label: <span className="mx-4 inline-flex items-center justify-center">Germany</span>,
    icon: <span className="inline-flex items-center justify-center">🇩🇪</span>,
    key: 'de',
    disabled: !true,
  },
];

function LanguageDropdown(props: { className?: string }) {
  const CurrentLang = useAppSelector((state) => state.language.current);
  const dispatch = useAppDispatch();
  return (
    <Dropdown
      className={props.className}
      arrow
      overlayClassName="mt-2"
      menu={{
        onSelect: (args) => {
          dispatch(setCurrentLanguage(args.selectedKeys[0]));
        },
        items,
        selectable: true,
        multiple: false,
        defaultSelectedKeys: [CurrentLang],
      }}
    >
      <span data-cy="LanguageDropdown" className="rounded-xl border-2  border-gray-200 bg-white p-2 text-gray-500">
        {items
          ?.filter((lang) => lang?.key === CurrentLang)
          .map((Element: any, key: number) => (
            <Space key={key} size="small">
              {Element?.icon}
              {Element?.label}
            </Space>
          ))}
      </span>
    </Dropdown>
  );
}

export default LanguageDropdown;
