import { FiAlignJustify, FiArrowLeft } from 'react-icons/fi';
import { BiUser } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../UI/Dropdown/Dropdown';
import { readUser } from '../../services/storageServices';
import LanguageDropdown from '../Dropdown/LanguageDropdown';
import { PATHS, SIDEBAR_ELEMENTS, extractLinks } from '../../routes/menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from '../UI/Button/Button';
import Space from 'antd/es/space';

const extractedLinks = extractLinks(SIDEBAR_ELEMENTS);
function Navbar(props: { onOpenSidenav: () => void }) {
  const { onOpenSidenav } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = window.location.href.split('/').pop();
  const [secondary, setSecondary] = useState(false);
  const [currentRoute, setCurrentRoute] = useState('dashboard');

  const getActiveRoute = (routes: ExtraLink[]): string | boolean => {
    const activeRoute = 'dashboard';

    const foundRoute = routes.some((route) => {
      if (window.location.href.indexOf(route.path) !== -1) {
        setCurrentRoute(route.title);
        if (route?.subLinks?.length) {
          setSecondary(true);
          return route.subLinks.some((subLink) => {
            if (window.location.href.indexOf(subLink?.path?.replace?.(':id', id as string)) !== -1) {
              setCurrentRoute(subLink.title);
              return true;
            }
          });
        }
        return true;
      }
      setSecondary(false);
      return false;
    });

    return foundRoute ? activeRoute : false;
  };

  useEffect(() => {
    getActiveRoute(extractedLinks);
  }, [location.pathname]);

  return (
    <nav className="sticky top-0 z-40 mx-auto h-[150px] w-full flex-row items-center justify-between bg-white/10 backdrop-blur-xl md:flex md:h-24 md:flex-row-reverse xl:pl-2">
      <div className="relative flex w-full items-center justify-between rounded-xl bg-white p-2 shadow md:max-w-sm">
        <span className="flex  cursor-pointer text-xl text-gray-600  xl:hidden" onClick={onOpenSidenav}>
          <FiAlignJustify className="size-11 overflow-hidden rounded-xl border p-3 text-4xl shadow drop-shadow" />
        </span>
        {/* LanguageDropdown */}
        <LanguageDropdown />
        {/* Profile & Dropdown */}
        <Dropdown
          button={<BiUser className="size-11 overflow-hidden rounded-xl border p-3 text-4xl shadow drop-shadow" />}
          classNames="py-2 top-9 -left-[180px] w-max"
        >
          <div className="flex w-56 flex-col justify-start overflow-hidden rounded-xl border bg-white bg-cover bg-no-repeat shadow-xl">
            <div className="border-b p-4">
              <div className="flex items-center gap-2">
                <p className="text-sm font-bold text-navy-700">
                  {t('app.great')} <span className="ml-1 inline-block text-navy-700/50">{readUser()?.username}</span>
                </p>
              </div>
            </div>
            <div className="">
              <Link
                to={PATHS.AUTH.LOGOUT}
                state={{
                  from: window.location.pathname,
                }}
                className="inline-flex h-11 w-full items-center justify-center text-sm font-medium text-red-500 hover:bg-blueSecondary/5 hover:text-red-500"
              >
                {t('auth.logout.name')}
              </Link>
            </div>
          </div>
        </Dropdown>
      </div>
      <p className="mt-2 line-clamp-1 flex shrink items-center gap-x-4 text-navy-700">
        {secondary && (
          <Button
            onClick={() => {
              if (location?.state?.from) {
                navigate(location.state.from, {
                  state: { ...location?.state },
                });
              } else window.history.back();
            }}
            type="soft"
          >
            <FiArrowLeft />
          </Button>
        )}
        <Space className="text-[20px] font-medium xl:text-[25px]">
          <span className="">{t(currentRoute)}</span>
          {secondary && (
            <span title={location?.state?.target} className="line-clamp-1 text-navy-700/40">
              {location?.state?.target}{' '}
            </span>
          )}
        </Space>
      </p>
    </nav>
  );
}

export default Navbar;
