import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import { persistStore } from '../persistStore';
import { prefLanguageFromStore } from '../../services/storageServices';

interface ILanguage {
  current: 'fr' | 'en' | 'de';
}

const initialState: ILanguage = {
  current: prefLanguageFromStore() ?? 'fr',
};

export const languageSlide = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentLanguage: (state, action) => {
      i18n.changeLanguage(action.payload, () => {
        state.current = action.payload;
        persistStore.setItem('lang', action.payload);
      });
    },
  },
});

export default languageSlide.reducer;
export const { setCurrentLanguage } = languageSlide.actions;
