import { HiX } from 'react-icons/hi';
import Menu, { type MenuProps } from 'antd/es/menu';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemMenu } from './ItemMenu';
import { doAuthorization } from '../../routes/middlewares';
import { SIDEBAR_ELEMENTS } from '../../routes/menu';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

type Args = {
  ELEMENTS: TsideBarElement;
};

const ContentParser: React.FC<Args> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      {props.ELEMENTS.map((ELEMENT) => {
        if (doAuthorization(ELEMENT.access)) {
          switch (ELEMENT.type) {
            case 'SECTION':
              return (
                <Menu.ItemGroup key={ELEMENT.title} eventKey={ELEMENT.title} title={t(ELEMENT.title)}>
                  <ContentParser ELEMENTS={ELEMENT.childs} />
                </Menu.ItemGroup>
              );
              break;
            case 'MENU_GROUP':
              return (
                <Menu.SubMenu
                  className="ml-2 font-medium"
                  icon={<ELEMENT.icon className="size-5 font-medium" />}
                  key={ELEMENT.title}
                  title={t(ELEMENT.title)}
                >
                  <ContentParser ELEMENTS={ELEMENT.childs} />
                </Menu.SubMenu>
              );
            case 'MENU':
              return (
                <Menu.Item key={ELEMENT.title} eventKey={ELEMENT.title} title={t(ELEMENT.name)}>
                  <ItemMenu menu={ELEMENT} />
                </Menu.Item>
              );
            default:
              return <div className="text-red-500">Bad menu structure</div>;
          }
        }
      })}
    </>
  );
};

function Sidebar(props: { open: boolean; onClose: React.MouseEventHandler<HTMLSpanElement> }) {
  const { open, onClose } = props;
  const [current, setCurrent] = useState('1');

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, onClose);

  return (
    <div
      ref={wrapperRef}
      className={`fixed !z-50 flex min-h-full min-w-[255px]  max-w-[255px] flex-col overflow-hidden bg-white pb-10 shadow-2xl shadow-white/5 transition-all duration-100 md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? 'translate-x-0' : '-translate-x-96 xl:translate-x-0'
      }`}
    >
      <span className="absolute right-4 top-4 block cursor-pointer xl:hidden" onClick={onClose}>
        <HiX />
      </span>

      <div className="mb-3 flex h-[100px] items-center justify-center border-b">
        <img src="/assets/logoTransparent.png" className="h-full w-auto" />
      </div>

      <Menu onClick={onClick} mode="inline" selectedKeys={[current]} className="uppercase tracking-tighter">
        <ContentParser ELEMENTS={SIDEBAR_ELEMENTS} />
      </Menu>
    </div>
  );
}

export default Sidebar;
