import { Suspense, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import Sidebar from '../components/Sidebar/Sidebar';
import { doAuthentication, doAuthorization } from '../routes/middlewares';
import pages from '../routes/pages';
import { PATHS } from '../routes/menu';
import Spin from 'antd/es/spin';

function LayoutApp() {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    window.addEventListener('resize', () => (window.innerWidth < 1200 ? setOpen(false) : setOpen(true)));
  }, []);

  if (doAuthentication())
    return (
      <div className="flex size-full">
        <Sidebar open={open} onClose={() => setOpen(false)} />
        {/* Navbar & Main Content */}
        <div className="size-full bg-lightPrimary dark:!bg-navy-900">
          {/* Main Content */}
          <main className="mx-4 h-full flex-none transition-all xl:ml-[255px]">
            {/* Routes */}
            <div className="hideScrollBar relative max-h-screen min-h-screen overflow-y-scroll pb-11 xl:px-2">
              <Navbar onOpenSidenav={() => setOpen(true)} />
              <Routes>
                {pages.map((page) => (
                  <Route
                    path={page.path}
                    element={
                      doAuthorization(page.access) ? (
                        <Suspense
                          fallback={
                            <div className="flex h-screen w-screen items-center justify-center">
                              <Spin size="small" />
                            </div>
                          }
                        >
                          {page.component}
                        </Suspense>
                      ) : (
                        <Navigate
                          to={{
                            pathname: PATHS.REDIRECT.FORBIDDEN,
                          }}
                          state={{
                            prev: window.location.pathname,
                          }}
                        />
                      )
                    }
                    key={page.path}
                  >
                    {page.children}
                  </Route>
                ))}
              </Routes>
            </div>
          </main>
        </div>
      </div>
    );

  return (
    <Navigate
      to={{
        pathname: PATHS.AUTH.LOGIN,
      }}
    />
  );
}

export default LayoutApp;
