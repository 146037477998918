import Collapse from 'antd/es/collapse';
import Tooltip from 'antd/es/tooltip';
import { HiExclamationCircle, HiTrash } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { FieldArrayWithId, UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';
import Button from '../UI/Button/Button';
import BulkFormItem from './BulkFormItem';
import { BULK_OPERATION } from '../../constants';

type Args = {
  fields: FieldArrayWithId<any[]>[];
  formContext: UseFormReturn<any>;
  removeAnElement: UseFieldArrayRemove;
  inputs: object;
  bulkOperation: BULK_OPERATION;
};

function BulkFormContent({ fields, formContext, removeAnElement, inputs, bulkOperation }: Args) {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = formContext;

  return (
    <div className="h-[62vh] scroll-m-0 overflow-x-hidden overflow-y-scroll scroll-smooth">
      <Collapse
        accordion
        defaultActiveKey={[0]}
        items={fields.map((fields, index) => ({
          forceRender: true,
          key: index,
          label: (
            <div className="flex items-center gap-x-4">
              {
                // @ts-ignore
                errors?.[`${bulkOperation}`]?.[index] && (
                  <Tooltip
                    className="flex items-center gap-x-2"
                    placement="top"
                    title={t('general.texts.bulkItemErrorNofification')}
                    defaultOpen
                    trigger={['click', 'hover']}
                  >
                    <HiExclamationCircle className="mt-px text-3xl text-red-500" />
                    <div className="text-red-500">
                      {
                        // @ts-ignore
                        errors?.[`${bulkOperation}`]?.[index]?.error?.message ?? ''
                      }
                    </div>
                  </Tooltip>
                )
              }
              <div className="uppercase">
                {bulkOperation == BULK_OPERATION.SIM
                  ? 'Mobile'
                  : bulkOperation == BULK_OPERATION.SDA
                    ? 'TRUNK'
                    : bulkOperation}
              </div>
              <span />
            </div>
          ),
          children: (
            <BulkFormItem
              key={fields.id}
              bulkOperation={bulkOperation}
              itemPosition={index}
              fields={fields}
              inputs={inputs}
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
            />
          ),
          extra: (
            <Button
              type="danger"
              className="lg:mx-2"
              onClick={() => {
                removeAnElement(index);
              }}
            >
              <HiTrash />
            </Button>
          ),
        }))}
      />
    </div>
  );
}

export default BulkFormContent;
