import { useTranslation } from 'react-i18next';
import Button from '../UI/Button/Button';
import { HiPlus } from 'react-icons/hi';
import Title from 'antd/es/typography/Title';
import { COMMAND } from '../../constants';

type args = {
  isLoading: boolean;
  context: COMMAND;
  append: () => void;
  action: BulkAction;
};

function BulkFormPageHeader({ isLoading, context, append, action }: args) {
  const { t } = useTranslation();
  return (
    <div className="flex w-full items-center justify-between rounded-lg bg-white p-4 lg:px-10 ">
      <Title level={4}>
        {t(`${action}.title`, {
          name: t(`keys.${context}_other`, { defaultValue: context }),
        })}
      </Title>
      <Button
        type="primary-inverse"
        data-cy="bulkAppender"
        disabled={!['create', 'usage'].includes(action) || isLoading}
        onClick={() => {
          append();
        }}
      >
        <HiPlus />
      </Button>
    </div>
  );
}

export default BulkFormPageHeader;
