import { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import Spin from 'antd/es/spin';
import Mentions, { type MentionProps } from 'antd/es/mentions';
import { useTranslation } from 'react-i18next';

export interface DebounceMentionProps extends MentionProps {
  fetchOptions: (search?: string) => Promise<any[]>;
  debounceTimeout?: number;
  exludeValues?: unknown[];
  defaultKey?: string;
}

function DebounceMentions({ fetchOptions, debounceTimeout = 800, className, ...props }: DebounceMentionProps) {
  const [options, setOptions] = useState<any[]>([]);
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);
  const [search, setSearch] = useState<string | undefined>(() => props.defaultKey);
  const { t } = useTranslation();

  useEffect(() => {
    debounceFetcher(search);
  }, [search, fetchOptions]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (search?: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      const newOptions = await fetchOptions(search);
      if (fetchId === fetchRef.current) {
        setOptions(newOptions);
      }

      if (fetchId === fetchRef.current) {
        setFetching(false);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Mentions
      autoSize
      className={`mentions min-h-24 w-full ${className}`}
      filterOption={false}
      onSearch={(search) => {
        setSearch(search);
      }}
      notFoundContent={fetching ? <Spin size="small" /> : t('general.texts.emptyData')}
      {...props}
      options={options}
    />
  );
}

export default DebounceMentions;
