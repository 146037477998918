import i18n from 'i18next';
import { DateTime } from 'luxon';
import { initReactI18next } from 'react-i18next';
import fr_translation from './langs/fr/translation.json';
import en_translation from './langs/en/translation.json';
import de_translation from './langs/de/translation.json';
import { prefLanguageFromStore } from './services/storageServices';

const resources = {
  fr: {
    translation: fr_translation,
  },
  en: {
    translation: en_translation,
  },
  de: {
    translation: de_translation,
  },
};

i18n.use(initReactI18next).init({
  lng: prefLanguageFromStore() ?? 'fr',
  // fallbackLng:"fr",
  resources,
  interpolation: {
    escapeValue: false,
  },
});

i18n?.services?.formatter?.add('DATE_HUGE', (value, lng = 'fr') => {
  const date = DateTime.fromJSDate(new Date(value || undefined));
  if (date.isValid) return date.setLocale(lng).toLocaleString(DateTime.DATE_HUGE);
  return '-';
});
i18n?.services?.formatter?.add('TIME_24_SIMPLE', (value, lng = 'fr') => {
  const date = DateTime.fromJSDate(new Date(value || undefined));
  if (date.isValid) return date.setLocale(lng).toLocaleString(DateTime.TIME_24_SIMPLE);
  return '-';
});

export default i18n;
