import SelectInput from '../UI/fields/Select/Select';
import { SwitchInput } from '../UI/fields/Switch/Switch';
import Input from 'antd/es/input';
import { BULK_OPERATION, MNO_OPTIONS, SIM_SUSPENDRESUME_REQUEST_OPTIONS } from '../../constants';
import { CHECKBOXES } from '../../constants/bulkFormInput';
import { useLocation } from 'react-router';
import DebounceSelect from '../UI/fields/DebounceSelect/DebounceSelect';
import { SearchPhoneNumbersBy } from '../../services/phoneNumbersServices';
import { SearchCustomers } from '../../services/customerServices';
import { SearchFinalUserBy } from '../../services/finalUsersServices';
import { readUser } from '../../services/storageServices';
import {
  SearchCustomerIps,
  SearchMobileParams,
  SearchMobileServices,
  SearchSimSn,
} from '../../services/mobileExtraServices';
import DebounceMentions from '../UI/fields/Mentions/DebounceMentions';
import { SearchCountries } from '../../services/countriesServices';

function ParsedInput(
  input: string,
  bulkOperation: BULK_OPERATION,
  t: any,
  customer_name = readUser()?.username,
  country?: string,
) {
  const { pathname } = useLocation();
  const inputs: {
    [x: string]: any;
  } = {
    checkbox: {
      specificInput: SwitchInput,
      specificProps: {
        name: input,
        checkedChildren: t('form.inputs.portability.values.active'),
        unCheckedChildren: t('form.inputs.portability.values.inactive'),
      },
    },
    country: {
      specificInput: DebounceSelect,
      specificProps: {
        fetchOptions: SearchCountries('name', 'name'),
      },
    },

    porta_date: {
      specificInput: Input,
      specificProps: {
        type: 'date',
      },
    },
    requestPortability: {
      specificInput: SwitchInput,
      specificProps: {
        className: 'my-[13px]',
        checkedChildren: t('form.inputs.portability.values.active'),
        unCheckedChildren: t('form.inputs.portability.values.inactive'),
      },
    },
    registration: {
      specificProps: {
        className: 'my-[13px]',
        checkedChildren: <p className=""> {t('form.inputs.registration.values.registered')} </p>,
        unCheckedChildren: <p className="">{t('form.inputs.registration.values.unregistered')} </p>,
      },
      specificInput: SwitchInput,
    },
    parameters: {
      specificInput: DebounceMentions,
      specificProps: {
        fetchOptions: SearchMobileParams(),
        placeholder: `${t(`form.inputs.parameters.placeholderMentionsOne`)}
${t(`form.inputs.parameters.placeholderMentionsTwo`)}`,
      },
    },
    porta_time: {
      specificInput: Input,
      specificProps: {
        type: 'time',
      },
    },
    trunk_re_password: {
      specificInput: Input.Password,
    },
    trunk_password: {
      specificInput: Input.Password,
    },
    ip_address: {
      specificInput: DebounceSelect,
      specificProps: {
        addAutoToken: true,
        fetchOptions: SearchCustomerIps({ customer_name }),
      },
    },
    register_ip: {
      specificInput: DebounceSelect,
      specificProps: {
        addAutoToken: true,
        fetchOptions: SearchCustomerIps({ customer_name }),
      },
    },
    phone_number_data: {
      specificInput: DebounceSelect,
      specificProps: {
        showSearch: true,
        allowClear: true,
        fetchOptions: SearchPhoneNumbersBy({ customer_name, country }),
      },
    },
    trunk_number_data: {
      specificInput: DebounceSelect,
      specificProps: {
        showSearch: true,
        allowClear: true,
        fetchOptions: SearchPhoneNumbersBy({ is_trunk: true, customer_name, country }),
      },
    },
    customer_name: {
      specificInput: DebounceSelect,
      specificProps: {
        showSearch: true,
        allowClear: true,
        fetchOptions: SearchCustomers,
      },
    },
    date_of_birth: {
      specificInput: Input,
      specificProps: {
        disabled: true,
        type: 'date',
      },
    },
    request_type: {
      specificInput: SelectInput,
      specificProps: {
        options: SIM_SUSPENDRESUME_REQUEST_OPTIONS.map((option) => ({ ...option, label: t(option.label) })),
      },
    },
    final_user: {
      specificInput: DebounceSelect,
      specificProps: {
        showSearch: true,
        allowClear: true,
        fetchOptions: SearchFinalUserBy('client_code', { customer_name, country }),
      },
    },
    simsn: {
      specificInput: DebounceSelect,
      specificProps: {
        showSearch: true,
        allowClear: true,
        fetchOptions: SearchSimSn({ customer_name }),
      },
    },
    services: {
      specificInput: DebounceSelect,
      specificProps: {
        mode: [BULK_OPERATION.SIM].includes(bulkOperation) ? 'multiple' : undefined,
        disabled: pathname.includes('mobile_first'),
        fetchOptions: SearchMobileServices(),
      },
    },
    mno: {
      specificInput: SelectInput,
      specificProps: {
        options: MNO_OPTIONS,
      },
    },
  };
  input = CHECKBOXES.includes(input) ? 'checkbox' : input;
  return inputs[input] ?? {};
}

export default ParsedInput;
