import authReducer from './authSlice';
import sidebarSlice from './sidebarSlice';
import prefetchDataSlice from './prefetchDataSlice';
import languageSlice from './languageSlice';

export default {
  auth: authReducer,
  sidebar: sidebarSlice,
  preFetchData: prefetchDataSlice,
  language: languageSlice,
};
