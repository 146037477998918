import { ThemeConfig } from 'antd/es/config-provider';
import { Env } from '../utils';

type Config = {
  NODE_ENV: string;
  APP_NAME: string;
  STORAGE_SECRET: string;
  STORAGE_TYPE: 'localStorage' | 'sessionStorage';
  REQUEST_TIMEOUT: number;
  TOKEN_TYPE: 'Bearer' | 'Basic';
  API_PROTOCOL: 'http' | 'https';
  API_HOSTNAME: string;
  API_PORT: number;
  API_ENDPOINTS_PREFIX: string;
  API_BASE_URL: () => string;
  REQUEST_HEADER_AUTH_KEY: string;
  USE_ENCRYPT_STORE: boolean;
  UPLOAD_SIZE_LIMIT: number;
  SENTRY_DNS: string;
};

export const config: Config = {
  NODE_ENV: Env('NODE_ENV', 'development'),
  APP_NAME: Env('APP_TITLE', 'Legos WebCare default'),
  STORAGE_SECRET: Env('STORAGE_SECRET', '%DefaultSecret'),
  STORAGE_TYPE: Env('STORAGE_TYPE', 'sessionStorage'),
  REQUEST_TIMEOUT: Env('REQUEST_TIMEOUT', 120000),
  TOKEN_TYPE: Env('TOKEN_TYPE', 'Basic'),
  API_PROTOCOL: Env('API_PROTOCOL', 'https'),
  API_HOSTNAME: Env('API_HOSTNAME', 'fasfox.net'),
  API_PORT: Env('API_PORT', ''),
  API_ENDPOINTS_PREFIX: Env('API_ENDPOINTS_PREFIX', 'api'),
  API_BASE_URL: function (): string {
    return `${this.API_PROTOCOL}://${this.API_HOSTNAME}${this.API_PORT ? `:${this.API_PORT}` : ''}/${
      this.API_ENDPOINTS_PREFIX
    }`;
  },
  REQUEST_HEADER_AUTH_KEY: Env('REQUEST_HEADER_AUTH_KEY', 'Authorization'),
  USE_ENCRYPT_STORE: Env('USE_ENCRYPT_STORE', true) === 'false' ? false : true,
  UPLOAD_SIZE_LIMIT: Env('VITE_UPLOAD_SIZE_LIMIT', 0),
  SENTRY_DNS: Env('SENTRY_DNS', ''),
};

export const themeConfig: ThemeConfig = {
  token: {
    fontFamily: `'DM Sans','Outfit', 'Poppins', 'DM Sans', sans-serif`,
    colorTextBase: '#0b1437',
    borderRadius: 10,
    fontSize: 14,
    colorPrimary: '#422AFB',
    colorTextHeading: '#1b254b',
    colorPrimaryBg: '#f5f8fe',
    colorPrimaryBgHover: 'rgb(66,42,251,.2)',
  },
  components: {
    Button: {
      borderRadius: 12,
      controlHeight: 48,
    },
    Card: {
      borderRadiusLG: 16,
    },
    Select: {
      colorBorder: 'transparent',
      colorBgContainer: '#f5f8fe',
      controlHeight: 45,
    },
    Input: {
      controlHeight: 48,
      colorBgContainer: '#f5f8fe',
      colorBorder: '#e8f0fe',
    },
    Table: {
      colorPrimaryBg: '#422AFB',
      colorPrimaryBgHover: '#422AFB',
    },
  },
};
