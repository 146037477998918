import Button, { type ButtonProps } from 'antd/es/button';
import { BiLoader } from 'react-icons/bi';
interface Args extends Omit<ButtonProps, 'type'> {
  isLoading?: boolean;
  type?: 'primary' | 'link' | 'text' | 'default' | 'dashed' | 'danger' | 'soft' | 'simple' | 'primary-inverse';
}
function Clickable({ isLoading = false, disabled, className, type, children, ...rest }: Args) {
  return (
    <Button
      role="button"
      disabled={isLoading || disabled}
      className={`flex h-12  items-center justify-center border border-white bg-navy-50/50 p-2.5 px-4 text-base font-medium text-gray-700 transition-transform duration-75 ease-in active:scale-95 ${type} ${className}`}
      {...rest}
    >
      <BiLoader
        className={` ${isLoading ? '' : 'hidden'} absolute mx-auto inline-block animate-spin text-xl text-gray-400`}
      />
      <span className={`${isLoading ? 'opacity-0' : ''} mt-0.5`}>{children}</span>
    </Button>
  );
}

export default Clickable;
