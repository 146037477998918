import { HTTP, extractDataFrom } from './httpServices';
import { endpoints } from '../constants/endpoints';
import { AxiosResponse } from 'axios';
import { FinalUserCreateData } from './validations/validateFinalUser';
import { formatFinalUser } from '../utils';

export const creatFinalUser = (payload: FinalUserCreateData): Promise<AxiosResponse<FinalUser>> =>
  HTTP.post(endpoints.FINAL_USERS.LIST, payload);

export const startFinalUserValidation = (userCode: string): Promise<AxiosResponse<FinalUser>> =>
  HTTP.post(endpoints.FINAL_USERS.START_VALIDATATION(userCode));

export const extractFinalUsers = (options: ExtractOptions): Promise<void> => {
  return extractDataFrom(endpoints.FINAL_USERS.LIST, options);
};

export const getFinalUsers = ({ format, ...rest }: Pagination): Promise<PaginateList<FinalUser>> => {
  if (format) extractFinalUsers({ format, ...rest });
  return HTTP.get(`${endpoints.FINAL_USERS.LIST}`, {
    params: rest,
  }).then(({ data }) => data);
};

export const getFinalUser = (user_code: string): Promise<FinalUser> =>
  HTTP.get(endpoints.FINAL_USERS.ELEMENT(user_code)).then(({ data }) => data);

export const updateFinalUser = (user_code: string, data: FinalUserCreateData): Promise<AxiosResponse<FinalUser>> =>
  HTTP.put(endpoints.FINAL_USERS.ELEMENT(user_code), data);

export const deleteFinalUser = (user_code: string): Promise<FinalUser> =>
  HTTP.delete(endpoints.FINAL_USERS.ELEMENT(user_code)).then(({ data }) => data);

export function SearchFinalUserBy(
  target: keyof FinalUser,
  filter?: {
    [key: string]: any;
  },
) {
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getFinalUsers(pagination as Pagination).then((data) =>
      data.results.map((finalUser) => ({
        label: formatFinalUser(finalUser),
        value: finalUser[target as keyof typeof finalUser],
      })),
    );
  };
}
