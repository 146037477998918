import { HTTP, extractDataFrom } from './httpServices';
import { endpoints } from '../constants/endpoints';
import { PhoneNumberConfirmRequestData, PhoneNumberRequestData } from './validations/validatePhoneNumber';
import { AxiosResponse } from 'axios';
import { PortabilityData } from './validations/validateSda';
import i18n from '../i18n';
import { propositionType } from '../constants';
import { formatPhoneNumberStr } from '../utils';

export const requestPhoneNumbers = (payload: PhoneNumberRequestData): Promise<LegosProposition | EnreachProposition> =>
  HTTP.post(endpoints.PHONE_NUMBERS.ELEMENT_ATTRIBUTES, payload).then(({ data }) => {
    if (payload.country == 1) return { ...data, type: propositionType.LEGOS };
    return { ...data, type: propositionType.ENREACH };
  });

export const confirmRequestPhoneNumbers = (
  data: PhoneNumberConfirmRequestData | EnreachConfirmation,
): Promise<AxiosResponse<any>> =>
  HTTP.post(endpoints.PHONE_NUMBERS.ELEMENT_ATTRIBUTES_CONFIRM, data).then((res) => res);

export const extractPhoneNumbers = (options: ExtractOptions): Promise<void> => {
  return extractDataFrom(endpoints.TRUNK.LIST, options);
};

export const getPhoneNumbers = ({ format, ...rest }: Pagination): Promise<PaginateList<PhoneNumber>> => {
  if (format) extractPhoneNumbers({ format, ...rest });
  return HTTP.get(`${endpoints.PHONE_NUMBERS.LIST}`, {
    params: rest,
  }).then(({ data }) => data);
};

export const getPhoneNumber = (id: string): Promise<PhoneNumber> =>
  HTTP.get(endpoints.PHONE_NUMBERS.ELEMENT(id as any)).then(({ data }) => data);

export async function SearchPhoneNumber(query: string): Promise<any[]> {
  const pagination: Omit<Pagination, 'limit' | 'offset'> = {
    search: query,
  };
  return getPhoneNumbers(pagination as Pagination).then((data) =>
    data.results.map((phoneNumber) => ({
      label: `${formatPhoneNumberStr(phoneNumber)} ${i18n.t(`form.inputs.phoneNumberTypes.values.${phoneNumber.number_type}`, { defaultValue: '' })}`,
      value: JSON.stringify(phoneNumber),
    })),
  );
}

export function SearchPhoneNumbersBy(filter?: { [key: string]: any }) {
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getPhoneNumbers(pagination as Pagination).then((data) =>
      data.results.map((phoneNumber) => ({
        label: `${formatPhoneNumberStr(phoneNumber)} ${i18n.t(`form.inputs.phoneNumberTypes.values.${phoneNumber.number_type}`, { defaultValue: '' })}`,
        value: JSON.stringify(phoneNumber),
      })),
    );
  };
}

export async function SearchTrunkPhoneNumber(query: string): Promise<any[]> {
  const pagination: Omit<Pagination, 'limit' | 'offset'> = {
    search: query,
    is_trunk: true,
  };
  return getPhoneNumbers(pagination as Pagination).then((data) =>
    data.results.map((phoneNumber) => ({
      label: `${formatPhoneNumberStr(phoneNumber)} ${i18n.t(`form.inputs.phoneNumberTypes.values.${phoneNumber.number_type}`, { defaultValue: '' })}`,
      value: JSON.stringify(phoneNumber),
    })),
  );
}

export const requestPortability = (data: PortabilityData): Promise<AxiosResponse<PortabilityData>> =>
  HTTP.post(endpoints.TRUNK_PORTA.REQUEST_PORTA, data);
