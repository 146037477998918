import { Routes, Route } from 'react-router-dom';
import LayoutApp from './layouts/LayoutApp';
import LayoutAuth from './layouts/LayoutAuth';

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/auth/*" element={<LayoutAuth />} />
        <Route path="*" element={<LayoutApp />} />
      </Routes>
    </>
  );
}
