import { MdBroadcastOnHome, MdChecklist, MdDelete, MdList, MdMobileFriendly, MdModeEdit } from 'react-icons/md';
import { FiServer, FiUsers } from 'react-icons/fi';
import { RiListOrdered2 } from 'react-icons/ri';

export const PATHS = {
  REDIRECT: {
    FORBIDDEN: 'forbidden',
    SERVERERROR: 'serverFailed',
    RESSOURCENOTFOUND: '*',
  },
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/logout',
  },
  DASHBOARD: {
    INDEX: {
      LINK: '/dashboard',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
  },
  FINALUSERS: {
    LIST: {
      LINK: '/finalusers',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
    CREATE: {
      LINK: '/finalusers/create',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
    UPDATE: {
      LINK: (id: string = ':id') => `/finalusers/update/${id}`,
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
  },
  SERVICES: {
    INVENTAIRE: {
      LINK: '/inventaire',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
    ACTIVATION: {
      LINK: '/services_activation',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
    MODIFICATION: {
      LINK: '/services_modification',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
    SUPPRESSION: {
      LINK: '/services_delete',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
  },
  PHONENUMBERS: {
    LIST: {
      LINK: '/phonenumbers',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
    CREATE: {
      LINK: '/phonenumbers/create',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
    SLICES: {
      LIST: {
        LINK: '/phone_numbers_slices',
        ACCESS: {
          strict: false,
          permissions: [],
        },
      },
      DECLARE: {
        LINK: '/phone_numbers_slices/declare',
        ACCESS: {
          strict: false,
          permissions: [],
        },
      },
    },
  },
  SDA: {
    LIST: {
      LINK: '/inventaire/sdas',
      ACCESS: {
        strict: false,
        permissions: ['sda'],
      },
    },

    CREATE: {
      LINK: '/sdas/gestion',
      ACCESS: {
        strict: false,
        permissions: ['sda'],
      },
    },
    UPDATE: {
      LINK: '/sdas/gestion',
      ACCESS: {
        strict: false,
        permissions: ['sda'],
      },
    },
    REGISTER: {
      LINK: '/sdas/register',
      ACCESS: {
        strict: false,
        permissions: ['sda'],
      },
    },
    IMPORT: {
      LINK: 'import',
      ACCESS: {
        strict: false,
        permissions: ['sda'],
      },
    },
  },
  MVN: {
    LIST: {
      LINK: '/inventaire/mvns',
      ACCESS: {
        strict: false,
        permissions: ['mvn', 'mobile'],
      },
    },
    CREATE: {
      LINK: '/mvns/gestion',
      ACCESS: {
        strict: false,
        permissions: ['mvn', 'mobile'],
      },
    },
    UPDATE: {
      LINK: '/mvns/gestion',
      ACCESS: {
        strict: false,
        permissions: ['mvn', 'mobile'],
      },
    },
  },
  SIM: {
    LIST: {
      LINK: '/inventaire/sims',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    CREATE: {
      LINK: '/inventaire/sims/gestion',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    UPDATE: {
      LINK: '/inventaire/sims/gestion',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    REGISTER: {
      LINK: '/sims/register',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    SUSPENDRESUME: {
      LINK: '/sims/suspendResume',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    SWITCH: {
      LINK: '/sims/switch',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    USAGE: {
      LINK: '/sims/usage',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
  },
  MOBILE_FIRST: {
    LIST: {
      LINK: '/inventaire/mobile_first',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    CREATE: {
      LINK: '/inventaire/mobile_first/gestion',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    UPDATE: {
      LINK: '/inventaire/mobile_first/gestion',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    REGISTER: {
      LINK: '/mobile_first/register',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    SUSPENDRESUME: {
      LINK: '/mobile_first/suspendResume',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    SWITCH: {
      LINK: '/mobile_first/switch',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
    USAGE: {
      LINK: '/mobile_first/usage',
      ACCESS: {
        strict: false,
        permissions: ['mobile'],
      },
    },
  },
  TRUNK: {
    LIST: {
      LINK: '/inventaire/trunks_number',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
    CREATE: {
      LINK: '/inventaire/trunks_number/gestion',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
    UPDATE: {
      LINK: '/trunks_number/gestion',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
  },
  TRUNK_VOICE: {
    LIST: {
      LINK: '/inventaire/trunks_voice',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
    CREATE: {
      LINK: '/inventaire/trunks_voice/gestion',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
    UPDATE: {
      LINK: '/trunks_voice/gestion',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
  },
  TRUNK_SMS_P2P: {
    LIST: {
      LINK: '/inventaire/trunks_sms_p2p',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
    CREATE: {
      LINK: '/inventaire/trunks_sms_p2p/gestion',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
    UPDATE: {
      LINK: '/trunks_sms_p2p/gestion',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
  },
  TRUNK_SMS_A2P: {
    LIST: {
      LINK: '/inventaire/trunks_sms_a2p',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
    CREATE: {
      LINK: '/inventaire/trunks_sms_a2p/gestion',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
    UPDATE: {
      LINK: '/inventaire/trunks_sms_a2p/gestion',
      ACCESS: {
        strict: false,
        permissions: ['trunk'],
      },
    },
  },
  TRUNK_PORTABILITY: {
    LIST: {
      LINK: '/portaTrunk',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
    POST_PONE: {
      LINK: '/portaTrunk/post',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
    CANCEL: {
      LINK: '/portaTrunk/cancel',
      ACCESS: {
        strict: false,
        permissions: [],
      },
    },
  },
};

export const SIDEBAR_ELEMENTS: TsideBarElement = [
  // {
  //   type: 'MENU',
  //   icon: MdHome,
  //   path: PATHS.DASHBOARD.INDEX.LINK,
  //   title: 'pages.dashboard.name',
  //   access: PATHS.DASHBOARD.INDEX.ACCESS,
  // },
  {
    type: 'MENU',
    icon: FiUsers,
    path: PATHS.FINALUSERS.LIST.LINK,
    name: 'pages.finalUsers.name',
    title: 'pages.finalUsers.list.title',
    access: PATHS.FINALUSERS.LIST.ACCESS,
    subLinks: [
      {
        title: 'pages.finalUsers.create.title',
        path: PATHS.FINALUSERS.CREATE.LINK,
      },
      {
        title: 'pages.finalUsers.update.title',
        path: PATHS.FINALUSERS.UPDATE.LINK(),
      },
    ],
  },
  {
    type: 'SECTION',
    icon: MdMobileFriendly,
    title: 'menu.group.numbers.name',
    access: {
      strict: false,
      permissions: [],
    },
    childs: [
      {
        type: 'MENU',
        icon: RiListOrdered2,
        path: PATHS.PHONENUMBERS.LIST.LINK,
        title: 'pages.phoneNumbers.list.title',
        name: 'pages.phoneNumbers.name',
        access: PATHS.PHONENUMBERS.LIST.ACCESS,
        subLinks: [
          {
            title: 'pages.phoneNumbers.create.title',
            path: PATHS.PHONENUMBERS.CREATE.LINK,
          },
        ],
      },
      {
        type: 'MENU',
        icon: FiServer,
        path: PATHS.PHONENUMBERS.SLICES.LIST.LINK,
        name: 'pages.hosting.name',
        title: 'pages.hosting.list.title',
        access: PATHS.PHONENUMBERS.LIST.ACCESS,
        subLinks: [
          {
            title: 'pages.hosting.create.title',
            path: PATHS.PHONENUMBERS.SLICES.DECLARE.LINK,
          },
        ],
      },
      {
        type: 'MENU',
        icon: MdBroadcastOnHome,
        path: PATHS.TRUNK_PORTABILITY.LIST.LINK,
        name: 'pages.portability.name',
        title: 'pages.portability.list.title',
        access: PATHS.TRUNK_PORTABILITY.LIST.ACCESS,
        subLinks: [
          {
            title: 'pages.portability.create.title',
            path: PATHS.TRUNK_PORTABILITY.POST_PONE.LINK,
          },
        ],
      },
    ],
  },
  {
    type: 'SECTION',
    icon: MdMobileFriendly,
    title: 'menu.group.service.name',
    access: {
      strict: true,
      permissions: ['mobile', 'mvn'],
    },
    childs: [
      {
        type: 'MENU',
        title: 'pages.inventaire.title',
        name: 'pages.inventaire.name',
        path: PATHS.SERVICES.INVENTAIRE.LINK,
        icon: MdList,
        access: PATHS.TRUNK_SMS_A2P.LIST.ACCESS,
        subLinks: [
          //SDA
          {
            title: 'pages.inventaire.title',
            path: PATHS.SDA.IMPORT.LINK,
          },
          //MVN
          {
            title: 'pages.inventaire.title',
            path: PATHS.MVN.CREATE.LINK,
          },
          {
            title: 'pages.inventaire.title',
            path: PATHS.MVN.UPDATE.LINK,
          },
          //Trunk
          {
            title: 'pages.inventaire.title',
            path: PATHS.TRUNK.CREATE.LINK,
          },
          //Trunk A2P
          {
            title: 'pages.inventaire.title',
            path: PATHS.TRUNK_SMS_A2P.CREATE.LINK,
          },
          {
            title: 'pages.inventaire.title',
            path: PATHS.TRUNK_SMS_A2P.UPDATE.LINK,
          },
          //Trunk P2P
          {
            title: 'pages.inventaire.title',
            path: PATHS.TRUNK_SMS_P2P.CREATE.LINK,
          },
          {
            title: 'pages.inventaire.title',
            path: PATHS.TRUNK_SMS_P2P.UPDATE.LINK,
          },
          //Trunk Voice
          {
            title: 'pages.inventaire.title',
            path: PATHS.TRUNK_VOICE.CREATE.LINK,
          },
          {
            title: 'pages.inventaire.title',
            path: PATHS.TRUNK_VOICE.UPDATE.LINK,
          },
          //mobile first
          {
            title: 'pages.inventaire.title',
            path: PATHS.MOBILE_FIRST.CREATE.LINK,
          },
          {
            title: 'pages.inventaire.title',
            path: PATHS.MOBILE_FIRST.UPDATE.LINK,
          },
          //mobile sims
          {
            title: 'pages.inventaire.title',
            path: PATHS.SIM.CREATE.LINK,
          },
          {
            title: 'pages.inventaire.title',
            path: PATHS.SIM.UPDATE.LINK,
          },
        ],
      },
      {
        type: 'MENU',
        title: 'pages.service_activation.title',
        name: 'pages.service_activation.name',
        path: PATHS.SERVICES.ACTIVATION.LINK,
        icon: MdChecklist,
        access: PATHS.TRUNK_SMS_A2P.LIST.ACCESS,
        subLinks: [],
      },
      {
        type: 'MENU',
        title: 'pages.service_modification.title',
        name: 'pages.service_modification.name',
        path: PATHS.SERVICES.MODIFICATION.LINK,
        icon: MdModeEdit,
        access: PATHS.TRUNK_SMS_A2P.LIST.ACCESS,
        subLinks: [],
      },
      {
        type: 'MENU',
        title: 'pages.service_suppression.title',
        name: 'pages.service_suppression.name',
        path: PATHS.SERVICES.SUPPRESSION.LINK,
        icon: MdDelete,
        access: PATHS.TRUNK_SMS_A2P.LIST.ACCESS,
        subLinks: [],
      },
    ],
  },
];

export const extractLinks = (list: any[]): ExtraLink[] => {
  return list.reduce((acc: ExtraLink[], element: any) => {
    if (element.type === 'MENU') {
      acc.push({ path: element.path, title: element.title, subLinks: element.subLinks || [] });
    } else if (['MENU_GROUP', 'SECTION'].includes(element.type) && Array.isArray(element.childs)) {
      acc.push(...extractLinks(element.childs)); // Merge result back into accumulator
    }
    return acc;
  }, []);
};
