import Select, { type SelectProps } from 'antd/es/select';
import { forwardRef } from 'react';

interface mySelectProps extends SelectProps {
  placeholder?: string;
}
const SelectInput = forwardRef(({ placeholder, children, disabled, ...rest }: mySelectProps, ref: any) => (
  <Select
    {...rest}
    disabled={disabled}
    ref={ref}
    aria-autocomplete="none"
    allowClear
    placeholder={placeholder}
    className={`h-12 w-full rounded-[10px] border border-[#e8f0fe] ${disabled ? '!bg-gray-50/5' : 'border-gray-200'}`}
  >
    {children}
  </Select>
));

export default SelectInput;
