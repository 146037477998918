import { EncryptStorage } from 'encrypt-storage';
import { config } from '../constants/config';

const stores = {
  localStorage: localStorage,
  sessionStorage: sessionStorage,
};

export const persistStore = config.USE_ENCRYPT_STORE
  ? new EncryptStorage(`${config.STORAGE_SECRET}`, {
      storageType: config.STORAGE_TYPE,
    })
  : stores[config.STORAGE_TYPE];
