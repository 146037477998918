import { z } from 'zod';
import { TRUNK_TYPE } from '../../constants';

export const trunkSchema = z.object({
  id: z
    .number({
      required_error: 'general.validation.required',
    })
    .optional(),
  trunk_name: z
    .string({
      required_error: 'general.validation.required',
    })
    .nonempty({
      message: 'general.validation.required',
    }),
  user_agent: z
    .string({
      required_error: 'general.validation.required',
    })
    .nonempty({
      message: 'general.validation.required',
    }),
  ip_address: z
    .string({
      required_error: 'general.validation.required',
    })
    .ip({
      message: 'general.validation.invalid.addressIP',
    }),
  trunk_password: z
    .string({
      required_error: 'general.validation.required',
    })
    .nonempty({
      message: 'general.validation.required',
    }),
  is_virtual_trunk: z.boolean().default(false),
});
//-----------------CREATE FORM -------------------//
export const trunksCreateSchema = z.object({
  trunks: z.array(
    z
      .object({
        id: z
          .number({
            required_error: 'general.validation.required',
          })
          .optional(),
        customer_name: z.string().nullable().optional(),
        trunk_name: z
          .string({
            required_error: 'general.validation.required',
          })
          .nonempty({
            message: 'general.validation.required',
          }),
        trunk_type: z
          .enum(Object.values(TRUNK_TYPE) as any)
          .optional()
          .nullable(),
        user_agent: z
          .string({
            required_error: 'general.validation.required',
          })
          .nonempty({
            message: 'general.validation.required',
          }),
        ip_address: z
          .string({
            required_error: 'general.validation.required',
          })
          .ip({
            message: 'general.validation.invalid.addressIP',
          }),
        trunk_password: z
          .string({
            required_error: 'general.validation.required',
          })
          .nonempty({
            message: 'general.validation.required',
          }),
        trunk_re_password: z
          .string({
            required_error: 'general.validation.required',
          })
          .nonempty({
            message: 'general.validation.required',
          }),
        is_virtual_trunk: z.boolean().default(false),
      })
      .superRefine(({ trunk_password, trunk_re_password }, ctx) => {
        if (trunk_password !== trunk_re_password) {
          ctx.addIssue({
            code: 'custom',
            message: 'form.inputs.trunkRePassword.validation.invalid',
            path: ['trunk_re_password'],
          });
        }
      }),
  ),
});

export type TrunksCreateData = z.infer<typeof trunksCreateSchema>;
//-----------------DELETE FORM -------------------//
export const trunksDeleteSchema = z.object({
  trunks: z.array(
    z.object({
      id: z.number(),
      customer_name: z.string().nullable().optional(),
      ip_address: z
        .string({
          required_error: 'general.validation.required',
          invalid_type_error: 'general.validation.invalid.addressIP',
        })
        .ip({
          message: 'general.validation.invalid.addressIP',
        })
        .nonempty({
          message: 'general.validation.required',
        }),
    }),
  ),
});
export type TrunksDeleteData = z.infer<typeof trunksDeleteSchema>;
