import { getUserPermissions, readToken } from '../services/storageServices';

export type RoutesAccessType = {
  strict: boolean;
  permissions: string[];
};
export const doAuthentication = (): boolean => {
  return readToken('access') ? true : false;
};

export const doAuthorization = (
  options: RoutesAccessType = {
    strict: false,
    permissions: [],
  },
) => {
  const userPermissions = getUserPermissions();
  const authorizationFunction = options.strict ? hasAllPermissions : hasOneOrMorePermissions;
  return authorizationFunction(userPermissions, options.permissions);
};

export const hasOneOrMorePermissions = (allPermissions: string[], requiredPermissions: string[]) => {
  return (
    requiredPermissions.length === 0 || requiredPermissions.some((permission) => allPermissions.includes(permission))
  );
};

export const hasAllPermissions = (allPermissions: string[], requiredPermissions: string[]) => {
  return (
    requiredPermissions.length === 0 || requiredPermissions.every((permission) => allPermissions.includes(permission))
  );
};
