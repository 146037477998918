import { UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { toast } from 'react-toastify';
import { BULK_OPERATION, SERVICES_TYPES, SERVICE_OPERATIONS } from '../constants';

export function warnError(error: any, autoDismiss: boolean = true) {
  if (error) {
    if (Array.isArray(error)) error = error.join('|');
    if (autoDismiss) toast.dismiss();
    toast.warn(error);
  }
}
export function parseServerError(serverErrors: any, setError: any, bulkOperation?: BULK_OPERATION, index?: number) {
  warnError(serverErrors.error);
  const errorsEntries: [string, string[]][] = Object?.entries?.(serverErrors);
  errorsEntries.map((errorEntry: [string, string[]]) => {
    if (bulkOperation) {
      setError(
        `${bulkOperation}.${index}.${errorEntry[0]}`,
        {
          message: errorEntry?.[1]?.join?.('|') ?? errorEntry?.[1],
        },
        { shouldFocus: true },
      );
    } else {
      setError(
        errorEntry?.[0],
        {
          message: errorEntry?.[1]?.join?.('|') ?? errorEntry?.[1],
        },
        { shouldFocus: true },
      );
    }
  });
}

export function ServerErrorToArray(err: any) {
  const arr = [];
  for (const key in err) {
    arr.push(err[key]);
  }
  return arr;
}

export function bulkServerErrorsParser(err: any, setError: UseFormSetError<any>, bulkOperation: BULK_OPERATION) {
  warnError(err.response.data.error);
  const errorsArray = Array.isArray(err?.response?.data)
    ? err?.response?.data
    : ServerErrorToArray(err?.response?.data);
  errorsArray.forEach((errors: any, index: any) => {
    parseServerError(errors, setError, bulkOperation, index);
  });
}

export function parseServicesOperationsServerError(
  err: {
    [key: number]: {
      [key: string]: { [key: string]: string[] }[];
    };
    error?: string | string[];
  },
  setError: UseFormSetError<any>,
  setValue: UseFormSetValue<any>,
  seviceType: SERVICES_TYPES,
  positions: { position: number; is_slice: boolean }[],
) {
  positions.forEach(({ position }, index) => {
    warnError(err.error);

    if (location.pathname.includes(SERVICE_OPERATIONS.DELETE) && err?.[position].error) {
      setError(`operations.${position}.${seviceType}`, {
        //@ts-ignore
        message: err?.[position].error,
      });
    } else if (location.pathname.includes(SERVICE_OPERATIONS.DELETE)) {
      setValue(`operations.${position}.${seviceType}.done`, true);
    }

    if (!location.pathname.includes(SERVICE_OPERATIONS.DELETE)) {
      if (err[index])
        Object.entries(err[index]).forEach(([input, error]) => {
          error.forEach((inputError) => {
            if (typeof inputError === 'string') {
              setError(`operations.${position}.${seviceType}.${input}`, {
                //@ts-ignore
                message: inputError,
              });
            } else {
              const [[key, er]] = Object.entries(inputError);

              setError(`operations.${position}.${seviceType}.${input}.${key}`, {
                //@ts-ignore
                message: Array.isArray(er) ? er.join(',') : er,
              });
            }
          });
        });
      else if (!err?.error) setValue(`operations.${position}.${seviceType}.done`, true);
    }
  });
}
