import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigProvider from 'antd/es/config-provider';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import './index.css';
import { store } from './store/store';
import i18n from './i18n';
import App from './App';
import { config, themeConfig } from './constants/config';

import('@sentry/react').then((Sentry) => {
  Sentry.init({
    dsn: config.SENTRY_DNS,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ConfigProvider theme={themeConfig}>
            <App />
          </ConfigProvider>
          <ToastContainer
            hideProgressBar
            style={{
              borderRadius: 20,
            }}
          />
        </I18nextProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
