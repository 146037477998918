import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../UI/Button/Button';

type Args = {
  isLoading: boolean;
};

function BulkFormPageFooter({ isLoading }: Args) {
  const { t } = useTranslation();
  const navigage = useNavigate();
  return (
    <div className="mt-4 flex w-full items-center justify-end gap-x-5 rounded-lg bg-white p-4 lg:px-10">
      <Button onClick={() => navigage(-1)} className="w-1/2 md:w-1/4 lg:w-1/5">
        {t('general.buttons.goBack')}
      </Button>
      <Button isLoading={isLoading} htmlType="submit" type="primary" className="w-1/2 md:w-1/4 lg:w-1/5">
        {t('general.buttons.submit')}
      </Button>
    </div>
  );
}

export default BulkFormPageFooter;
