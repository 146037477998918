import { HTTP } from '../../services/httpServices';
import { endpoints } from '../../constants/endpoints';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

export interface ICountry {
  id: number;
  name: string;
  iso_code: string;
  isd_code: string;
}

export interface ICity {
  id: number;
  country: number;
  postal_code: string;
  name: string;
  prefix: string;
}

interface ICountryZoom extends ICountry {
  cities: ICity[];
}
interface IPrefetchDataState {
  countries: ICountry[];
  cities: {
    [key: number | string]: ICity[];
  };
}

const initialState: IPrefetchDataState = {
  countries: [],
  cities: {},
};

export const fetchCountries = createAsyncThunk(
  '/prefetch/countries',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const res: AxiosResponse<any> = await HTTP.get(endpoints.COUNTRIES.LIST, { params });
      return res.data.results;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCountry = createAsyncThunk('/prefetch/cities', async (payload: number, { rejectWithValue }) => {
  try {
    const res: AxiosResponse<ICountryZoom> = await HTTP.get(endpoints.COUNTRIES.ELEMENT(payload));
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const prefetchDataSlice = createSlice({
  name: 'prefetch',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<ICountry[]>) => {
      state.countries = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.fulfilled, (state, action: PayloadAction<ICountry[]>) => {
      state.countries = action.payload;
    });
    builder.addCase(fetchCountry.fulfilled, (state, action: PayloadAction<ICountryZoom>) => {
      state.cities[action.payload.id] = action.payload.cities;
      state.cities[action.payload.name] = action.payload.cities;
    });
  },
});

export const { setCountries } = prefetchDataSlice.actions;

export default prefetchDataSlice.reducer;
