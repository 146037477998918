import { Navigate, Route } from 'react-router';
import { PATHS } from './menu';
import { lazy } from 'react';
import FormTrunks from '../pages/Trunks/FormTrunks';
import { TRUNK_TYPE } from '../constants';
const PageTrunks = lazy(() => import('../pages/Trunks/PageTrunks'));
const PageDashboard = lazy(() => import('../pages/PageDashboard'));
const PageFinalUsers = lazy(() => import('../pages/FinalUsers/PageFinalUsers/PageFinalUsers'));
const FormPhonneNumbers = lazy(() => import('../pages/PhoneNumbers/FormPhoneNumbers'));
const PageSdas = lazy(() => import('../pages/Sdas/PageSdas'));
const PageSims = lazy(() => import('../pages/Sims/PageSims'));
const PageMvns = lazy(() => import('../pages/Mvns/PageMvns'));
// const PageTrunks = lazy(() => import('../pages/Trunks/PageTrunks'));
const PageLogout = lazy(() => import('../pages/PageLogout'));
const FormFinalUsers = lazy(() => import('../pages/FinalUsers/FormFinalUsers/FormFinalUsers'));
const FormSdas = lazy(() => import('../pages/Sdas/FormSdas'));
const FormMvns = lazy(() => import('../pages/Mvns/FormMvns'));
// const FormTrunks = lazy(() => import('../pages/Trunks/FormTrunks'));
const PageRessourceNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'));
const FormSims = lazy(() => import('../pages/Sims/FormSims'));
const PagePhoneNumbers = lazy(() => import('../pages/PhoneNumbers/PagePhoneNumbers/PagePhoneNumbers'));
const FormRegisterSdas = lazy(() => import('../pages/Sdas/FormRegisterSdas'));
const FormRegisterSims = lazy(() => import('../pages/Sims/FormRegisterSims'));
const FormSuspendResumeSims = lazy(() => import('../pages/Sims/FormSuspendResumeSims'));
const FormSwitchSims = lazy(() => import('../pages/Sims/FormSwitchSims'));
const PagePhoneNumbersSlices = lazy(() => import('../pages/PhoneNumbers/Hosted/PageHostedNumbers'));
const PageDeclarePhoneNumbers = lazy(() => import('../pages/PhoneNumbers/DeclarePhoneNumbers/PageDeclarePhoneNumbers'));
const FormImportSda = lazy(() => import('../pages/Sdas/Import/FormImportSda'));
const PageForbidden = lazy(() => import('../pages/PageForbidden/PageForbidden'));
const FormSimsUsage = lazy(() => import('../pages/Sims/FormSimsUsage'));
const PageTrunksPorta = lazy(() => import('../pages/TrunksPorta/PagePortability'));
const FormTrunkPortaPostPone = lazy(() => import('../pages/TrunksPorta/FormPortability'));
const FormTrunksPortaCancel = lazy(() => import('../pages/TrunksPorta/FormTrunksPortaCancel'));
const FormServiceActivation = lazy(() => import('../pages/services/FormServiceActivation'));
const PageServices = lazy(() => import('../pages/services/PageServices'));

const pages: Array<Page> = [
  //* *************  REDIRECT TO DASHBOARD **************//
  {
    path: '/',
    access: {
      strict: false,
      permissions: [],
    },
    component: (
      <Navigate
        to={{
          pathname: PATHS.FINALUSERS.LIST.LINK,
        }}
      />
    ),
  },
  //* *************  DASHBOARD  **************//
  {
    path: PATHS.DASHBOARD.INDEX.LINK,
    access: PATHS.DASHBOARD.INDEX.ACCESS,
    component: <PageDashboard />,
  },
  //* ************* FINALS USERS **************//
  {
    path: PATHS.FINALUSERS.LIST.LINK,
    access: PATHS.FINALUSERS.LIST.ACCESS,
    component: <PageFinalUsers />,
  },
  {
    path: PATHS.FINALUSERS.CREATE.LINK,
    access: PATHS.FINALUSERS.CREATE.ACCESS,
    component: <FormFinalUsers />,
  },
  {
    path: PATHS.FINALUSERS.UPDATE.LINK(),
    access: PATHS.FINALUSERS.UPDATE.ACCESS,
    component: <FormFinalUsers />,
  },
  //* ************* PHONE NUMBERS **************//
  {
    path: PATHS.PHONENUMBERS.LIST.LINK,
    access: PATHS.PHONENUMBERS.LIST.ACCESS,
    component: <PagePhoneNumbers />,
  },
  {
    path: PATHS.PHONENUMBERS.CREATE.LINK,
    access: PATHS.PHONENUMBERS.CREATE.ACCESS,
    component: <FormPhonneNumbers />,
  },
  //* ************* PHONE NUMBERS SLICES **************//
  {
    path: PATHS.PHONENUMBERS.SLICES.LIST.LINK,
    access: PATHS.PHONENUMBERS.SLICES.LIST.ACCESS,
    component: <PagePhoneNumbersSlices />,
  },
  {
    path: PATHS.PHONENUMBERS.SLICES.DECLARE.LINK,
    access: PATHS.PHONENUMBERS.SLICES.DECLARE.ACCESS,
    component: <PageDeclarePhoneNumbers />,
  },
  //* *************  MOBILES SDA  **************//
  // {
  //   path: PATHS.SDA.LIST.LINK,
  //   access: PATHS.SDA.LIST.ACCESS,
  //   component: <PageSdas />,
  // },
  {
    path: PATHS.SDA.CREATE.LINK,
    access: PATHS.SDA.CREATE.ACCESS,
    component: <FormSdas />,
  },
  {
    path: PATHS.SDA.IMPORT.LINK,
    access: PATHS.SDA.IMPORT.ACCESS,
    component: <FormImportSda />,
  },
  {
    path: PATHS.SDA.REGISTER.LINK,
    access: PATHS.SDA.REGISTER.ACCESS,
    component: <FormRegisterSdas />,
  },
  //* ************* MOBILES  MVN **************//
  // {
  //   path: PATHS.MVN.LIST.LINK,
  //   access: PATHS.MVN.LIST.ACCESS,
  //   component: <PageMvns />,
  // },
  {
    path: PATHS.MVN.CREATE.LINK,
    access: PATHS.MVN.CREATE.ACCESS,
    component: <FormMvns />,
  },
  //* ************* MOBILES SIM **************//
  // {
  //   path: PATHS.SIM.LIST.LINK,
  //   access: PATHS.SIM.LIST.ACCESS,
  //   component: <PageSims />,
  // },
  {
    path: PATHS.SIM.CREATE.LINK,
    access: PATHS.SIM.CREATE.ACCESS,
    component: <FormSims />,
  },
  {
    path: PATHS.SIM.REGISTER.LINK,
    access: PATHS.SIM.REGISTER.ACCESS,
    component: <FormRegisterSims />,
  },
  {
    path: PATHS.SIM.SUSPENDRESUME.LINK,
    access: PATHS.SIM.SUSPENDRESUME.ACCESS,
    component: <FormSuspendResumeSims />,
  },
  {
    path: PATHS.SIM.SWITCH.LINK,
    access: PATHS.SIM.SWITCH.ACCESS,
    component: <FormSwitchSims />,
  },
  {
    path: PATHS.SIM.USAGE.LINK,
    access: PATHS.SIM.USAGE.ACCESS,
    component: <FormSimsUsage />,
  },
  //* ************* MOBILE FIRST **************//
  // {
  //   path: PATHS.MOBILE_FIRST.LIST.LINK,
  //   access: PATHS.MOBILE_FIRST.LIST.ACCESS,
  //   component: <PageSims />,
  // },
  {
    path: PATHS.MOBILE_FIRST.CREATE.LINK,
    access: PATHS.MOBILE_FIRST.CREATE.ACCESS,
    component: <FormSims />,
  },
  {
    path: PATHS.MOBILE_FIRST.REGISTER.LINK,
    access: PATHS.MOBILE_FIRST.REGISTER.ACCESS,
    component: <FormRegisterSims />,
  },
  {
    path: PATHS.MOBILE_FIRST.SUSPENDRESUME.LINK,
    access: PATHS.MOBILE_FIRST.SUSPENDRESUME.ACCESS,
    component: <FormSuspendResumeSims />,
  },
  {
    path: PATHS.MOBILE_FIRST.SWITCH.LINK,
    access: PATHS.MOBILE_FIRST.SWITCH.ACCESS,
    component: <FormSwitchSims />,
  },
  {
    path: PATHS.MOBILE_FIRST.USAGE.LINK,
    access: PATHS.MOBILE_FIRST.USAGE.ACCESS,
    component: <FormSimsUsage />,
  },
  //* *************  TRUNK  **************//
  // {
  //   path: PATHS.TRUNK.LIST.LINK,
  //   access: PATHS.TRUNK.LIST.ACCESS,
  //   component: <PageTrunks />,
  // },
  // {
  //   path: PATHS.TRUNK.CREATE.LINK,
  //   access: PATHS.TRUNK.CREATE.ACCESS,
  //   component: <FormTrunks />,
  // },
  //* *************  TRUNK VOICE  **************//
  // {
  //   path: PATHS.TRUNK_VOICE.LIST.LINK,
  //   access: PATHS.TRUNK_VOICE.LIST.ACCESS,
  //   component: <PageTrunks />,
  // },
  // {
  //   path: PATHS.TRUNK_VOICE.CREATE.LINK,
  //   access: PATHS.TRUNK.CREATE.ACCESS,
  //   component: <FormTrunks />,
  // },
  //* *************  TRUNK SMS P2P **************//
  // {
  //   path: PATHS.TRUNK_SMS_P2P.LIST.LINK,
  //   access: PATHS.TRUNK_SMS_P2P.LIST.ACCESS,
  //   component: <PageTrunks />,
  // },
  // {
  //   path: PATHS.TRUNK_SMS_P2P.CREATE.LINK,
  //   access: PATHS.TRUNK_SMS_P2P.CREATE.ACCESS,
  //   component: <FormTrunks />,
  // },
  //* *************  TRUNK SMS A2P  **************//
  // {
  //   path: PATHS.TRUNK_SMS_A2P.LIST.LINK,
  //   access: PATHS.TRUNK_SMS_A2P.LIST.ACCESS,
  //   component: <PageTrunks />,
  // },
  // {
  //   path: PATHS.TRUNK_SMS_A2P.CREATE.LINK,
  //   access: PATHS.TRUNK_SMS_A2P.CREATE.ACCESS,
  //   component: <FormTrunks />,
  // },
  //* ************* PORTABILITY TRUNK  **************//
  {
    path: PATHS.TRUNK_PORTABILITY.LIST.LINK,
    access: PATHS.TRUNK_PORTABILITY.LIST.ACCESS,
    component: <PageTrunksPorta />,
  },
  {
    path: PATHS.TRUNK_PORTABILITY.POST_PONE.LINK,
    access: PATHS.TRUNK_PORTABILITY.POST_PONE.ACCESS,
    component: <FormTrunkPortaPostPone />,
  },
  {
    path: PATHS.TRUNK_PORTABILITY.CANCEL.LINK,
    access: PATHS.TRUNK_PORTABILITY.CANCEL.ACCESS,
    component: <FormTrunksPortaCancel />,
  },
  //* ************* SERVICES MANAGMENTS  **************//
  {
    path: PATHS.SERVICES.INVENTAIRE.LINK,
    access: PATHS.TRUNK_PORTABILITY.CANCEL.ACCESS,
    component: <PageServices />,
    children: (
      <>
        <Route path={PATHS.TRUNK.LIST.LINK} element={<PageTrunks />} />
        <Route path={PATHS.TRUNK_VOICE.LIST.LINK} element={<PageSdas />} />
        <Route path={PATHS.TRUNK_SMS_A2P.LIST.LINK} element={<PageSdas />} />
        <Route path={PATHS.TRUNK_SMS_P2P.LIST.LINK} element={<PageSdas />} />
        {/* <Route path={PATHS.SDA.LIST.LINK} element={<PageSdas />} /> */}
        <Route path={PATHS.MVN.LIST.LINK} element={<PageMvns />} />
        <Route path={PATHS.SIM.LIST.LINK} element={<PageSims />} />
        <Route path={PATHS.MOBILE_FIRST.LIST.LINK} element={<PageSims />} />
        <Route path={`${PATHS.TRUNK.LIST.LINK}/` + PATHS.SDA.IMPORT.LINK} element={<FormImportSda />} />
        <Route path={`${PATHS.TRUNK_SMS_A2P.LIST.LINK}/` + PATHS.SDA.IMPORT.LINK} element={<FormImportSda />} />
        <Route path={`${PATHS.TRUNK_VOICE.LIST.LINK}/` + PATHS.SDA.IMPORT.LINK} element={<FormImportSda />} />
        <Route path={`${PATHS.TRUNK_SMS_P2P.LIST.LINK}/` + PATHS.SDA.IMPORT.LINK} element={<FormImportSda />} />
        <Route path={PATHS.TRUNK.CREATE.LINK} element={<FormTrunks />} />
        <Route path={PATHS.TRUNK_VOICE.CREATE.LINK} element={<FormSdas trunkType={TRUNK_TYPE.VOICE} />} />
        <Route path={PATHS.TRUNK_SMS_A2P.CREATE.LINK} element={<FormSdas trunkType={TRUNK_TYPE.SMS_A2P} />} />
        <Route path={PATHS.TRUNK_SMS_P2P.CREATE.LINK} element={<FormSdas trunkType={TRUNK_TYPE.SMS_P2P} />} />
        <Route path={PATHS.MOBILE_FIRST.CREATE.LINK} element={<FormSims />} />
        <Route path={PATHS.SIM.CREATE.LINK} element={<FormSims />} />
      </>
    ),
  },
  {
    path: PATHS.SERVICES.ACTIVATION.LINK,
    component: <FormServiceActivation />,
  },
  {
    path: PATHS.SERVICES.MODIFICATION.LINK,
    component: <FormServiceActivation />,
  },
  {
    path: PATHS.SERVICES.SUPPRESSION.LINK,
    component: <FormServiceActivation />,
  },
  //* *************  AUTH MANAGEMENTS **************//
  {
    path: PATHS.AUTH.LOGOUT,
    component: <PageLogout />,
  },
  {
    path: PATHS.REDIRECT.FORBIDDEN,
    component: <PageForbidden />,
  },
  //* *************  404 PAGE **************//
  {
    path: PATHS.REDIRECT.RESSOURCENOTFOUND,
    component: <PageRessourceNotFound />,
  },
];

export default pages;
