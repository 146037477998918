import Switch from 'antd/es/switch';
import { forwardRef } from 'react';

export const SwitchInput = forwardRef((props: any, ref) => {
  return (
    <Switch
      style={{
        background: props.value ? '#4318FF' : '#a0aec0',
      }}
      className="border uppercase"
      size="large"
      {...props}
      checked={props.value}
      ref={ref}
    >
      {props?.children}
    </Switch>
  );
});
