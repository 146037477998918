import { responseErrorLogger } from './httpServices';
import { endpoints } from '../constants/endpoints';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import axios, { AxiosError } from 'axios';
import { LoginData } from './validations/validateLoginForm';
import { config } from '../constants/config';
import { prefLanguageFromStore } from './storageServices';

axios.interceptors.response.use(undefined, (error: AxiosError<any>) => {
  responseErrorLogger(error);
  throw error;
});

axios.interceptors.request.use(
  async (request) => {
    request.headers['Accept-Language'] = prefLanguageFromStore();
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const login = (loginPayload: LoginData): Promise<LoginResponse> =>
  axios
    .post<LoginResponse>(`${config.API_BASE_URL()}/${endpoints.AUTH.LOGIN}`, { ...loginPayload })
    .then(({ data }) => data);

export const refreshSession = (refresh: string): Promise<refreshTokenResponse> =>
  axios.post(`${config.API_BASE_URL()}/${endpoints.AUTH.REFRESH_TOKEN}`, { refresh }).then(({ data }) => data);

export const isTokenExpired = (token: string): boolean => {
  const decodedToken = jwt_decode<JwtPayload>(token);
  if (decodedToken && decodedToken.exp) {
    if (decodedToken.exp < Date.now() / 1000) {
      return true;
    }
  }
  return false;
};
