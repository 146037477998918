import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  persistToken,
  persistUser,
  persistUserPermissions,
  readToken,
  unsetSession,
} from '../../services/storageServices';
import { login } from '../../services/authServices';
import { LoginData } from '../../services/validations/validateLoginForm';
import { ADMIN_PERMISSION } from '../../constants';

interface IAuthState {
  token: string | null;
}

const initialState: IAuthState = {
  token: readToken('access'),
};

export const doLogin = createAsyncThunk('/auth/login', async (loginPayload: LoginData, { rejectWithValue }) => {
  try {
    const { access, refresh, user } = await login(loginPayload);
    persistUser({ username: user.customer_name });
    persistToken('access', access);
    persistToken('refresh', refresh);

    const permissions = user.permissions;
    if (user.is_admin) {
      permissions.push(ADMIN_PERMISSION);
    }
    persistUserPermissions(permissions);
    return access;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const doLogout = createAsyncThunk('/auth/logout', async (_payload, { rejectWithValue }) => {
  try {
    unsetSession();
    return true;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = null;
    });
  },
});

export default authSlice.reducer;
