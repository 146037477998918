import FormItem from 'antd/es/form/FormItem';
import Title from 'antd/es/typography/Title';
import RequiredFieldIndicator from '../UI/RequiredFieldIndicator/RequiredFieldIndicator';
import ParsedInput from './ParsedInput';
import ErrorDisplayer from '../errors/ErrorInputDisplayer';
import ControlledInput from '../ControlledInput/ControlledInput';
import Input from 'antd/es/input';
import Space from 'antd/es/space';

const GeneratedFormItem = (input: string, props: any, t: any, customer_name?: string, country?: string) => {
  const { specificProps, specificInput } = ParsedInput(input, props.bulkOperation, t, customer_name, country);
  return (
    <FormItem
      key={input}
      label={
        <Space size="small">
          <Title className="!text-navy-700/70" level={5}>
            {t(`form.inputs.${props.inputs[input].label}.label`)}
          </Title>
          {props.inputs[input]?.required && <RequiredFieldIndicator />}
        </Space>
      }
      name={input}
      className="my-1.5"
    >
      <ControlledInput
        name={`${props.bulkOperation}.${props.itemPosition}.${input}`}
        controledInput={specificInput ?? Input}
        errors={props.errors}
        control={props.control}
        specificProps={specificProps}
      />
      <ErrorDisplayer
        name={input == 'phone_number_data' ? ['phone_number_data', 'phone_number'] : input}
        errors={props?.errors?.[props.bulkOperation]?.[props.itemPosition]}
      />
    </FormItem>
  );
};
export default GeneratedFormItem;
