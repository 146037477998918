import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { bulkServerErrorsParser } from '../../utils/serverErrorsParser';
import BulkFormPageHeader from '../../components/BulkForm/BulkFormPageHeader';
import BulkFormContent from '../../components/BulkForm/BulkFormContent';
import BulkFormPageFooter from '../../components/BulkForm/BulkFormPageFooter';
import { TrunksCreateData, trunksCreateSchema } from '../../services/validations/validateTrunk';
import { FormOptions, formatTrunkData, trunkTypeByLocationPaths } from '../../utils';
import { createTrunks, getTrunk, updateTrunks } from '../../services/trunksServices';
import { BULK_OPERATION, COMMAND } from '../../constants';
import { PATHS } from '../../routes/menu';

const inputs = {
  // final_user: { label: 'finalUser', required: true },
  country: { label: 'country', required: true },
  customer_name: { label: 'customerName', required: false },
  trunk_name: { label: 'trunkName', required: true },
  // phone_number: { label: 'phoneNumber', required: true },
  user_agent: { label: 'userAgent', required: true },
  ip_address: { label: 'registerIP', required: true },
  trunk_password: { label: 'trunkPassword', required: true },
  trunk_re_password: { label: 'trunkRePassword', required: true },
  // selfcare_password: { label: 'selfcarePassword', required: true },
  is_virtual_trunk: { label: 'isVirtualTrunk', required: false },
};

const bulkOperation = BULK_OPERATION.TRUNK;

function FormTrunks() {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const trunksToModify = location?.state?.selectedTrunks;
  const finalUser = location?.state?.finalUser;
  const navigate = useNavigate();
  const formContext = useForm<TrunksCreateData>(FormOptions(trunksCreateSchema));
  const { control, handleSubmit, setError, clearErrors, reset } = formContext;
  const { fields, append, remove, replace } = useFieldArray<TrunksCreateData>({
    name: bulkOperation,
    control,
  });

  useEffect(() => {
    if (trunksToModify?.length) {
      setIsLoading(true);
      Promise.all(trunksToModify.map((trunk_id: number) => getTrunk(trunk_id)))
        .then((trunks) => {
          //@ts-ignore
          replace(trunks.map((trunk) => formatTrunkData(trunk)) as TrunksCreateData['trunks']);
        })
        .catch(() => {
          toast.error(t('general.texts.errorLoadingElementsToUpdate'));
        })
        .finally(() => setIsLoading(false));
    }
    if (finalUser) {
      //@ts-ignore
      replace([formatTrunkData(undefined, finalUser)] as TrunksCreateData['trunks']);
    } else {
      replace([finalUser]);
    }
  }, [trunksToModify, location, finalUser]);

  const onSubmit: SubmitHandler<TrunksCreateData> = (data) => {
    clearErrors();
    setIsLoading(true);
    const Do = !trunksToModify?.length ? createTrunks(data) : updateTrunks(data);
    Do.then(() => {
      toast.success(t('httpResponseMessage.200'));
      reset();
      navigate(PATHS.TRUNK.LIST.LINK);
    })
      .catch((err) => {
        bulkServerErrorsParser(err, setError, bulkOperation);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="">
      <BulkFormPageHeader
        append={() => {
          //@ts-ignore
          append([formatTrunkData(undefined, finalUser)] as TrunksCreateData['trunks']);
        }}
        isLoading={isLoading}
        context={t(`form.inputs.services_operations.values.${trunkTypeByLocationPaths[location.pathname]}`, {
          defaultValue: COMMAND.TRUNK,
        })}
        action={trunksToModify ? 'update' : 'create'}
      />
      <Spin spinning={isLoading}>
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)} className="my-4">
          <BulkFormContent
            fields={fields}
            removeAnElement={remove}
            bulkOperation={bulkOperation}
            inputs={inputs}
            formContext={formContext}
          />
          <BulkFormPageFooter isLoading={isLoading} />
        </Form>
      </Spin>
    </div>
  );
}

export default FormTrunks;
