import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import FooterAuth from '../components/Footers/FooterAuth/FooterAuth';
import { doAuthentication } from '../routes/middlewares';
import PageAuth from '../pages/PageLogin';
import LanguageDropdown from '../components/Dropdown/LanguageDropdown';
import { PATHS } from '../routes/menu';

function LayoutAuth() {
  const navigate = useNavigate();
  useEffect(() => {
    if (doAuthentication()) {
      navigate(PATHS.FINALUSERS.LIST.LINK);
    }
  }, [navigate]);

  return (
    <div>
      <div className="relative float-right size-full min-h-screen !bg-white">
        <LanguageDropdown className="fixed right-[35px] top-[30px] !z-[99] flex items-center justify-center bg-white" />
        <main className="mx-auto min-h-screen">
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-screen xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col px-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <Link to="/" className="mt-0 w-max lg:pt-10">
                  <div className="mx-auto flex h-[100px] w-fit items-center hover:cursor-pointer">
                    <img src="/assets/logoTransparent.png" className="h-full w-auto" />
                  </div>
                </Link>
                <Routes>
                  <Route path="*" element={<PageAuth />} />
                </Routes>
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex size-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
                    style={{
                      backgroundImage: 'url(/assets/auth.jpg)',
                    }}
                  />
                  <div className="size-full overflow-hidden backdrop-blur-sm backdrop-contrast-150 backdrop-opacity-70 lg:rounded-bl-[120px] xl:rounded-bl-[200px] " />
                </div>
              </div>
              <FooterAuth />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default LayoutAuth;
