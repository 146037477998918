import { useMemo } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function ErrorDisplayer<T extends FieldValues>(props: { name: string | string[]; errors: FieldErrors<T> }) {
  const { t } = useTranslation();

  const mapedErrors = useMemo(() => {
    const errorsName = Array.isArray(props.name) ? props.name : [props.name];
    return errorsName.map((name) => {
      const validation: string = props?.errors?.[name]?.message?.toString?.()?.trim?.() ?? '';
      const message: string = validation.split?.('<=>')?.[0].trim?.();
      let params: string = validation.split?.('<=>')?.[1];
      if (params) {
        params = JSON.parse(params);
      }
      return {
        name,
        message,
        params,
      };
    });
  }, [props]);

  return mapedErrors.map(({ name, message, params }) => (
    <div
      key={name}
      data-cy={`${name}-error`}
      className="mt-1.5 text-xs font-medium text-red-400 first-letter:uppercase"
    >
      {t(message, params)}
    </div>
  ));
}

export default ErrorDisplayer;
