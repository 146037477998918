import { FieldValues, FieldErrors, Control, Path, Controller } from 'react-hook-form';
import { inputHasError } from '../../utils';

function ControlledInput<T extends FieldValues>(props: {
  controledInput: React.ComponentType<any>;
  errors: FieldErrors<T>;
  name: Path<T>;
  control: Control<T>;
  specificProps?: any;
}) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        const allProps = {
          ...field,
          ...props.specificProps,
          status: inputHasError<T>(props.name, props.errors),
        };
        return <props.controledInput {...allProps} />;
      }}
    />
  );
}

export default ControlledInput;
