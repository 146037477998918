import { HTTP } from './httpServices';
import { endpoints } from '../constants/endpoints';

export const getCustomers = (params: Pagination): Promise<PaginateList<Customer>> => {
  return HTTP.get(`${endpoints.USERS.LIST}`, {
    params,
  }).then(({ data }) => data);
};

export async function SearchCustomers(query: string): Promise<any[]> {
  const pagination: Omit<Pagination, 'limit' | 'offset'> = {
    search: query,
  };
  return getCustomers(pagination as Pagination).then((data) =>
    data.results.map(({ customer_name }) => ({
      label: customer_name,
      value: customer_name,
    })),
  );
}

export function SearchCustomersBy(filter?: { [key: string]: any }) {
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getCustomers(pagination as Pagination).then((data) =>
      data.results.map(({ customer_name }) => ({
        label: customer_name,
        value: customer_name,
      })),
    );
  };
}
