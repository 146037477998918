import { Key, TableRowSelection } from 'antd/es/table/interface';
import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { SetStateAction } from 'react';
import { ResponseType } from 'axios';
import Upload, { RcFile } from 'antd/es/upload';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { DeepPartial, FieldErrors, FieldValues, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { Dates } from '../constants/date';
import { PATHS } from '../routes/menu';
import { COUNTRIES_IDS, SERVICES_TYPES, SERVICE_OPERATIONS, TRUNK_TYPE } from '../constants';
import { ServicesOperationsData } from '../services/validations/validateServicesOperation';
import { parseServicesOperationsServerError } from './serverErrorsParser';
import { PortabilityData } from '../services/validations/validateSda';
import { isEqual } from 'lodash';

export const Env = (envVar: unknown, defaultValue: unknown) => {
  return import.meta.env[`VITE_${envVar}`] ?? defaultValue;
};

export function inputHasError<T extends FieldValues>(name: string, errors: FieldErrors<T>): string {
  const segments = name.split('.');
  let currentErrors: FieldErrors<T> | undefined = errors;
  const hasErrors = segments.every((segment) => {
    if (currentErrors && typeof currentErrors === 'object') {
      currentErrors = currentErrors[segment] as FieldErrors<T>;
      return currentErrors;
    }
    return currentErrors;
  });

  return hasErrors ? 'error' : '';
}
export function removeKeys<T extends object, K extends keyof T>(obj: T, keysToRemove: K[]): Omit<T, K> {
  const newObj = { ...obj };
  keysToRemove.forEach((key) => {
    delete newObj[key];
  });
  return newObj;
}
export function nullateKeys<T extends object, K extends keyof T>(obj: T, keysToSetNull: K[]): T {
  const newObj: Partial<T> = { ...obj };
  keysToSetNull.forEach((key) => {
    newObj[key] = null as T[K] | undefined;
  });
  return newObj as T;
}

export function replaceEmptyKeysWithNull<T extends object>(obj: T): T {
  const newObj: Partial<T> = { ...obj };
  for (const key in newObj) {
    if (Object.hasOwnProperty.call(newObj, key) && (newObj[key] === '' || newObj[key] === null)) {
      newObj[key] = null as any;
    }
  }
  return newObj as T;
}

export function deleteEmptyKeys<T extends object>(obj: T): T {
  const newObj: Partial<T> = { ...obj };
  for (const key in newObj) {
    if (
      Object.hasOwnProperty.call(newObj, key) &&
      (newObj[key] === '' || newObj[key] === null || newObj[key] === undefined)
    ) {
      delete newObj[key];
    }
  }
  return newObj as T;
}

export function pickFromObject<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keysToSelect: K[],
): Pick<T, K> {
  const selectedObject: Partial<T> = {};

  keysToSelect.forEach((key) => {
    if (key in obj) {
      selectedObject[key] = obj[key];
    }
  });

  return selectedObject as Pick<T, K>;
}

export const downloadFromDOM = (data: MediaSource, resHeader: AxiosResponseHeaders extends any ? any : any) => {
  const url = URL.createObjectURL(data);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${resHeader?.[`content-disposition`]?.split('=')[1]}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export function snakeCaseToCamelCase(str: string): string {
  if (!str || str.length === 0) {
    return str.trim();
  }
  return str
    .toLocaleLowerCase()
    .replace(/_./g, (match) => match.charAt(1).toUpperCase())
    .trim();
}

type popupArgs = {
  visible: boolean;
  x: number;
  y: number;
};

export function ContextMenuHandler(
  selectedRowKeys: Key[],
  setPopup: React.Dispatch<React.SetStateAction<popupArgs>>,
  popup: popupArgs,
  event: React.MouseEvent,
) {
  event.preventDefault();
  if (selectedRowKeys.length) {
    if (!popup.visible) {
      document.addEventListener(`click`, function onClickOutside() {
        setPopup((prev) => ({ ...prev, visible: false }));
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    setPopup({
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  }
}

export function onRowDoubleClick(
  record: any,
  selectedRowKeys: Key[],
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<Key[]>>,
  e: React.MouseEvent,
) {
  e.preventDefault();
  const selectedKeys = selectedRowKeys.includes(record.id)
    ? selectedRowKeys.filter((id) => id !== record.id)
    : [...selectedRowKeys, record.id];
  setSelectedRowKeys(selectedKeys);
}

export const statusColors: {
  [key: string]: string;
} = {
  suspend_impaye: 'warning',
  suspendu: 'warning',
  en_cours_ouverture: 'blue',
  inactive: 'red',
  active: 'green',
  Affectee: 'blue',
  Libre: 'green',
  ok: 'green',
  failed: 'red',
  waiting: 'warning',
  pending: 'yellow',
  pending_porta: 'green',
  error: 'blue',
  valid: 'warning',
  invalid: 'red',
  in_progress: 'blue',
  verified: 'green',
  'A CONFIRMER': 'yellow',
  REFUSE: 'red',
};

export const portabilityColors: {
  [key: string]: string;
} = {
  OK: 'green',
  requested: 'blue',
  outgoing: 'warning',
  NO: 'red',
  restit: 'green',
  cancelled: 'red',
  in_progress: 'blue',
};

export const requestTypeColors: {
  [key: string]: string;
} = {
  creation: 'green',
  modification: 'blue',
  suppression: 'red',
  activation: 'green-inverse',
  inscription_annuaire: 'blue-inverse',
  annulation_porta: 'warning',
  report_porta: 'orange',
};

export const paginationConfig = (pagination: any, setPagination: any, total: number) => {
  return {
    pageSize: pagination.limit,
    current: pagination.offset / pagination.limit + 1,
    total: total,
    pageSizeOptions: [5, 10, 30, 50, 100, 200],
    onChange: (offset: number, limit: number) =>
      setPagination((prev: any) => ({ ...prev, offset: (offset - 1) * limit, limit })),
    responsive: true,
    showPrevNextJumpers: true,
    showSizeChanger: true,
  };
};

export const rowSelectionConfig = (
  selectedRowKeys: Key[],
  setSelectedRowKeys: React.Dispatch<SetStateAction<Key[]>>,
): TableRowSelection<any> => {
  return {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    fixed: 'left',
  };
};

export const RequestConfig = (
  fileType: any,
): {
  responseType: ResponseType;
  headers: {
    Accept: string;
  };
} => {
  const Accepts: {
    [key: string]: string;
  } = {
    pdf: 'application/pdf',
    json: 'application/json',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };
  const responseType: {
    [key: string]: ResponseType;
  } = {
    xlsx: 'blob',
    pdf: 'blob',
  };
  return {
    responseType: responseType[fileType] ?? 'json',
    headers: {
      Accept: Accepts[fileType],
    },
  };
};

export const checkTypes = (types: string[]) => (file: RcFile) => {
  const goodType = types.includes(file.type);
  if (!goodType) {
    toast.error(i18next.t('form.inputs.file.validation.invalid.type'));
    return Upload.LIST_IGNORE;
  }
  const goodSize = file.size / 1024 / 1024 < 10;
  if (!goodSize) {
    toast.error(i18next.t('form.inputs.file.validation.invalid.size'));
    return Upload.LIST_IGNORE;
  }
  return false;
};

export function FormOptions(Schema: any): DeepPartial<any> {
  return {
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: null,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    delayError: 1000,
  };
}

export function formatUsername(user?: FinalUser, optionalKey?: keyof FinalUser) {
  return `${user?.first_name ?? user?.corporate_name} ${user?.last_name ?? user?.company_name} ${(optionalKey && user?.[optionalKey]) ?? ''}`;
}

export function formatFinalUser(finalUser?: FinalUser) {
  return finalUser ? `${formatUsername(finalUser)} (${finalUser.client_code})` : '-';
}

export const formatSdaData = (sda?: Sda, finalUser?: FinalUser) => {
  if (finalUser)
    return {
      ...finalUser,
      country: finalUser.country,
      city: finalUser.city,
      final_user: finalUser.client_code,
      naf_code: finalUser.naf,
      siret_code: finalUser.siret,
      postal_address: finalUser.address,
      postal_code: finalUser.post_code,
      select_user: {
        label: formatUsername(finalUser),
        value: finalUser.client_code,
      },
    };
  return {
    ...sda,
    country: sda?.final_user?.country,
    city: sda?.final_user?.city,
    ip_address: sda?.register_ip,
    phone_number_data: {
      label: `${sda?.phone_number.country_code} ${sda?.phone_number.phone_number} ${i18next.t(`form.inputs.phoneNumberTypes.values.${sda?.phone_number.number_type}`)}`,
      value: JSON.stringify(sda?.phone_number),
      key: sda?.phone_number?.phone_number,
    },
    trunk_number_data: {
      label: sda?.trunk_number,
      value: sda?.trunk_number,
      key: sda?.trunk_number,
    },
    phone_number: sda?.phone_number.phone_number,
    final_user: sda?.final_user.client_code,
    postal_code: sda?.final_user?.post_code,
    is_company: sda?.final_user?.is_company,
    select_user: {
      label: formatUsername(sda?.final_user),
      value: sda?.final_user.client_code,
    },
  };
};

export const formatSimData = (sim?: Sim, finalUser?: FinalUser) => {
  if (finalUser)
    return {
      ...finalUser,
      final_user: finalUser?.client_code,
      postal_code: finalUser?.post_code,
      postal_address: finalUser?.address,
      select_user: {
        label: formatUsername(finalUser),
        value: finalUser.client_code,
      },
    };
  return {
    ...sim,
    phone_number: sim?.phone_number?.phone_number,
    phone_number_data: sim?.phone_number?.phone_number,
    final_user: sim?.final_user?.client_code,
    is_company: sim?.final_user?.is_company,
    country: sim?.final_user?.country,
    city: sim?.final_user?.city,
    services: sim?.services?.split('|'),
  };
};
export const formatTrunkData = (trunk?: Trunk, finalUser?: FinalUser) => {
  if (finalUser)
    return {
      ...finalUser,
      final_user: finalUser?.client_code,
      select_user: {
        label: formatUsername(finalUser),
        value: finalUser.client_code,
      },
    };
  return {
    ...trunk,
    ip_address: trunk?.phone_number.customer_offer.ip_host.split(';')?.[0],
    customer_name: trunk?.phone_number.customer_name,
    phone_number: trunk?.phone_number?.phone_number,
    country: trunk?.phone_number.country_name,
    final_user: trunk?.final_user?.client_code,
    trunk_password: trunk?.phone_number.sip_pass,
    trunk_re_password: trunk?.phone_number.sip_pass,
  };
};

export const formatMvnData = (mvn?: Mvn, finalUser?: FinalUser) => {
  if (finalUser)
    return {
      ...finalUser,
      final_user: finalUser?.client_code,
      postal_address: finalUser?.address,
      select_user: {
        label: formatUsername(finalUser),
        value: finalUser.client_code,
      },
    };
  return {
    ...mvn,
    phone_number: mvn?.phone_number?.phone_number,
    final_user: mvn?.final_user?.client_code,
    is_company: mvn?.final_user?.is_company,
  };
};

export const formatFinalUserData = (finalUser?: FinalUser) => {
  return {
    ...finalUser,
    final_user: finalUser?.client_code,
    naf_code: finalUser?.naf,
    siret_code: finalUser?.siret,
    postal_address: finalUser?.address,
    postal_code: finalUser?.post_code,
    date_of_birth: Dates.format(finalUser?.date_of_birth ?? '', 'YYYY-MM-DD'),
    contract_start: Dates.format(finalUser?.contract_start ?? '', 'YYYY-MM-DD'),
    contract_end: Dates.format(finalUser?.contract_end ?? '', 'YYYY-MM-DD'),
  };
};

export const formatPortabilityNumber = (data: PortaTrunk): PortabilityData => {
  return {
    is_slice: false,
    head_line: data?.nd,
    phone_number: data?.phone_number_sda?.phone_number,
    rio_op_code: data?.phone_number_sda?.porta_donor_rio || '',
    opd: data?.operator || '',
    porta_time: data?.phone_number_sda?.porta_time || '',
    porta_date: data?.validated_porta_date || data?.phone_number_sda?.porta_date || '',
    country: data?.phone_number_sda?.country_id || COUNTRIES_IDS.France,
    number_type: data?.phone_number_sda?.number_type,
    final_user: data?.final_user?.client_code,
    customer_name: data?.phone_number_sda?.customer_name,
    services: Object.keys(data?.phone_number_sda?.services || {}),
  };
};

export const trunkTypeByLocationPaths = {
  [PATHS.TRUNK_VOICE.CREATE.LINK]: TRUNK_TYPE.VOICE,
  [PATHS.TRUNK_VOICE.LIST.LINK]: TRUNK_TYPE.VOICE,
  [PATHS.TRUNK_SMS_P2P.CREATE.LINK]: TRUNK_TYPE.SMS_P2P,
  [PATHS.TRUNK_SMS_P2P.LIST.LINK]: TRUNK_TYPE.SMS_P2P,
  [PATHS.TRUNK_SMS_A2P.CREATE.LINK]: TRUNK_TYPE.SMS_A2P,
  [PATHS.TRUNK_SMS_A2P.LIST.LINK]: TRUNK_TYPE.SMS_A2P,
};

export function toggleElementInArray<T>(array: T[], element: T): T[] {
  const index = array.indexOf(element);
  const newArray = [...array];
  if (index === -1) {
    newArray.push(element);
  } else {
    newArray.splice(index, 1);
  }
  return newArray;
}

export function getOperationsByType({ operations }: ServicesOperationsData) {
  const requests: Record<SERVICES_TYPES, any[]> = {
    [SERVICES_TYPES.MOBILE_FIRST]: [],
    [SERVICES_TYPES.TRUNK]: [],
    [SERVICES_TYPES.TRUNK_SMS_A2P]: [],
    [SERVICES_TYPES.TRUNK_VOICE]: [],
    [SERVICES_TYPES.TRUNK_SMS_P2P]: [],
    [SERVICES_TYPES.MOBILE_SIM]: [],
    [SERVICES_TYPES.MOBILE_MVN]: [],
    [SERVICES_TYPES.MOBILE_SDA]: [],
  };
  for (let position = 0; position < operations.length; position++) {
    //@ts-ignore
    const {
      //@ts-ignore
      country,
      //@ts-ignore
      is_slice,
      //@ts-ignore
      end_slice,
      //@ts-ignore
      start_slice,
      //@ts-ignore
      phone_number,
      //@ts-ignore
      final_user,
      //@ts-ignore
      services,
      customer_name,
      ...othersProps
    } = operations[position];
    for (const service of services) {
      //@ts-ignore
      const serviceData = othersProps?.[service];
      if (serviceData?.done) continue;
      const trunk_type = service.includes(SERVICES_TYPES.TRUNK) ? service : undefined;
      requests[service as SERVICES_TYPES].push({
        operationPosition: position,
        country,
        phone_number: is_slice ? `${start_slice}:${end_slice}` : phone_number,
        final_user,
        customer_name,
        trunk_type,
        ...serviceData,
        is_slice,
      });
    }
  }
  return requests;
}

export async function processService(
  serviceArray: any[],
  requestServiceFunc: (data: any) => Promise<AxiosResponse<any[]>>,
  serviceType: SERVICES_TYPES,
  setError: UseFormSetError<any>,
  setValue: UseFormSetValue<any>,
) {
  if (!serviceArray.length) return;
  const deleteContext = location.pathname.includes(SERVICE_OPERATIONS.DELETE);
  const [res] = await Promise.allSettled([
    serviceArray.length
      ? deleteContext
        ? requestServiceFunc(
            serviceType.includes(SERVICES_TYPES.TRUNK)
              ? {
                  ips: serviceArray.map(({ ip_address }) => ip_address).join(','),
                  ids: serviceArray.map(({ id }) => id).join(','),
                  customer_name: serviceArray.map(({ customer_name }) => customer_name).join(','),
                }
              : serviceArray.map(({ id }) => id),
          )
        : requestServiceFunc({
            [serviceType.includes(SERVICES_TYPES.TRUNK) ? 'sdas' : 'sims']: serviceArray,
          })
      : undefined,
  ]);

  //@ts-ignore
  if (!res?.reason) {
    serviceArray.forEach(({ operationPosition }) => {
      setValue(`operations.${operationPosition}.${serviceType}.done`, true);
    });
  } else {
    parseServicesOperationsServerError(
      //@ts-ignore
      res?.reason?.response?.data,
      setError,
      setValue,
      serviceType,
      serviceArray.map(({ operationPosition, is_slice }: any) => ({ position: operationPosition, is_slice })),
    );
  }
}

export function verifyItemsHasSameProperty<T>(items: T[], keys: (keyof T)[]) {
  if (items.length === 0) {
    return false;
  }
  return keys.every((key) => {
    const firstItemValue = items[0][key];
    return items.every((item) => isEqual(item[key], firstItemValue));
  });
}

const FR_CODE = `33`;

export const formatPhoneNumberStr = ({ phone_number, country_code }: PhoneNumber) => {
  return `${country_code} ${country_code.includes(FR_CODE) ? (phone_number.startsWith('0') ? phone_number.slice(1) : phone_number) : phone_number}`;
};

export function formatParametersStr(input: string): string {
  const regex = /([A-Za-z0-9_]+)\s*=\s*(\d+)/g;
  const matches: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = regex.exec(input)) !== null) {
    const key = match[1].trim();
    const value = match[2].trim();
    matches.push(`${key}=${value}`);
  }
  return matches.join('|');
}
