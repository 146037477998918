import { HTTP, extractDataFrom } from './httpServices';
import { endpoints } from '../constants/endpoints';
import type { Key } from 'antd/es/table/interface';
import { TrunksCreateData } from './validations/validateTrunk';
import { AxiosResponse } from 'axios';

export const createTrunks = (data: TrunksCreateData): Promise<AxiosResponse<Trunk[]>> =>
  HTTP.post(endpoints.TRUNK.BULK.CREATE, data.trunks);

export const extractTrunks = (options: ExtractOptions): Promise<void> => {
  return extractDataFrom(endpoints.TRUNK.LIST, options);
};

export const getTrunks = ({ format, ...rest }: Pagination): Promise<PaginateList<Trunk>> => {
  if (format) extractTrunks({ format, ...rest });
  return HTTP.get(`${endpoints.TRUNK.LIST}`, {
    params: rest,
  }).then(({ data }) => data);
};

export const getTrunk = (id: number): Promise<Trunk> => HTTP.get(endpoints.TRUNK.ELEMENT(id)).then(({ data }) => data);

export const updateTrunks = (data: TrunksCreateData): Promise<AxiosResponse<Trunk[]>> =>
  HTTP.put(endpoints.TRUNK.BULK.UPDATE, data.trunks);

export const deleteTrunks = ({
  Ids,
  IPs,
  customer_name,
}: {
  IPs: string[];
  Ids: Key[];
  customer_name: (string | null | undefined)[];
}): Promise<Trunk> => {
  return HTTP.delete(endpoints.TRUNK.BULK.DELETE, {
    params: {
      ids: Ids.join(','),
      ips: IPs.join(','),
      customer_name: customer_name.join(','),
    },
  }).then(({ data }) => data);
};
