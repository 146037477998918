import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export const ItemMenu = (props: { menu: Menu }): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();
  const { menu } = props;

  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  return (
    <Link to={menu.path}>
      <div className="relative flex  hover:cursor-pointer">
        <span className="flex cursor-pointer items-center">
          <span className={`${activeRoute(menu.path) ? 'font-bold text-brand-500 ' : 'font-medium text-gray-600'}`}>
            {<menu.icon className="size-4" />}
          </span>
          <p
            className={`ml-2 flex ${activeRoute(menu.path) ? 'font-bold text-navy-700' : 'font-medium text-gray-600'}`}
          >
            {t(menu.name)}
          </p>
        </span>
        {activeRoute(menu.path) && <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500" />}
      </div>
    </Link>
  );
};
