import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pickFromObject, removeKeys } from '../../utils';
import { BULK_OPERATION, MOBILE_SERVICES } from '../../constants';
import { doAuthorization } from '../../routes/middlewares';
import { ALL_USER_INPUTS, CHECKBOXES, PORTA_INPUTS } from '../../constants/bulkFormInput';
import GeneratedFormItem from './GeneratedFormItem';

export default function BulkFormItem(props: {
  bulkOperation: BULK_OPERATION;
  itemPosition: number;
  fields: any;
  inputs: any;
  className?: string;
  watch: any;
  errors: any;
  control: any;
  setValue: any;
}) {
  const [requestPortability, setRequestPortability] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>();
  const [country, setCountry] = useState<string>();

  const { t } = useTranslation();
  const checkboxesInputs = useMemo(() => {
    let obj = pickFromObject(props.inputs, CHECKBOXES);
    obj = removeKeys(obj, ALL_USER_INPUTS.concat(PORTA_INPUTS));
    return Object.keys(obj);
  }, [props.inputs]);

  const contextInputs = useMemo(() => {
    let obj = removeKeys(
      props.inputs,
      ALL_USER_INPUTS.concat(CHECKBOXES).concat(
        doAuthorization({
          strict: true,
          permissions: ['admin'],
        })
          ? ''
          : 'customer_name',
      ),
    );
    if (!requestPortability) {
      obj = removeKeys(obj as any, PORTA_INPUTS);
    }

    return Object.keys(obj);
  }, [props.inputs]);

  useEffect(() => {
    const subscription = props.watch(
      (
        value: any,
        info: {
          name: string;
        },
      ) => {
        if (info.name === `${props.bulkOperation}.${props.itemPosition}.country`) {
          setCountry(value[props.bulkOperation][props.itemPosition].country);
          props.setValue(`${props.bulkOperation}.${props.itemPosition}.phone_number`, '');
          props.setValue(`${props.bulkOperation}.${props.itemPosition}.phone_number_data`, '');
        }
        if (info.name === `${props.bulkOperation}.${props.itemPosition}.requestPortability`) {
          setRequestPortability(value[props.bulkOperation][props.itemPosition].requestPortability);
        }
        if (info.name === `${props.bulkOperation}.${props.itemPosition}.customer_name`) {
          setSelectedCustomer(value[props.bulkOperation][props.itemPosition].customer_name);
          props.setValue(`${props.bulkOperation}.${props.itemPosition}.phone_number`, '');
          props.setValue(`${props.bulkOperation}.${props.itemPosition}.phone_number_data`, '');
        }

        if (info.name === `${props.bulkOperation}.${props.itemPosition}.phone_number_data`) {
          const { phone_number } = JSON.parse(
            value[props.bulkOperation][props.itemPosition].phone_number_data,
          ) as PhoneNumber;
          props.setValue(`${props.bulkOperation}.${props.itemPosition}.phone_number`, phone_number);
        }

        if (info.name === `${props.bulkOperation}.${props.itemPosition}.trunk_number_data`) {
          const { phone_number } = JSON.parse(
            value[props.bulkOperation][props.itemPosition].trunk_number_data,
          ) as PhoneNumber;
          props.setValue(`${props.bulkOperation}.${props.itemPosition}.trunk_number`, phone_number);
        }
      },
    );
    location.pathname.includes('mobile_first') &&
      props.setValue(`${props.bulkOperation}.${props.itemPosition}.services`, [MOBILE_SERVICES.MF_V]);
    return () => subscription.unsubscribe();
  }, [props.watch]);

  return (
    <div className={`grid w-full gap-x-4 md:grid-cols-2 ${props.className}`}>
      {contextInputs.map((input: string) => GeneratedFormItem(input, props, t, selectedCustomer, country))}
      <div className="flex justify-between">
        {checkboxesInputs.map((input: string) => GeneratedFormItem(input, props, t, selectedCustomer, country))}
      </div>
    </div>
  );
}
