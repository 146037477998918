import { endpoints } from '../constants/endpoints';
import { HTTP } from './httpServices';

/**********************************SIM SN ***************************/
export const getSimSn = (params: Pagination): Promise<PaginateList<{ id: string; simsn: string }>> => {
  return HTTP.get(`${endpoints.EXTRA_MOBILE.SIMSN.LIST}`, {
    params,
  }).then(({ data }) => data);
};

export function SearchSimSn(filter?: { [key: string]: any }) {
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getSimSn(pagination as Pagination).then((data) =>
      data.results.map((simsn) => ({
        label: `${simsn.simsn}`,
        value: simsn.simsn,
      })),
    );
  };
}

/*************************************** MOBILE SERVICES *********************** */
export const getMobilesServices = (params: Pagination): Promise<PaginateList<{ id: string; code: string }>> => {
  return HTTP.get(`${endpoints.EXTRA_MOBILE.SERVICES.LIST}`, {
    params,
  }).then(({ data }) => data);
};

export function SearchMobileServices(filter?: { [key: string]: any }) {
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getMobilesServices(pagination as Pagination).then((data) =>
      data.results.map((simsn) => ({
        label: `${simsn.code}`,
        value: simsn.code,
      })),
    );
  };
}

/*************************************** MOBILE PARAMS *********************** */
export const getMobilesParams = (params: Pagination): Promise<PaginateList<{ id: string; code: string }>> => {
  return HTTP.get(`${endpoints.EXTRA_MOBILE.PARAMS.LIST}`, {
    params,
  }).then(({ data }) => data);
};

export function SearchMobileParams(filter?: { [key: string]: any }) {
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getMobilesParams(pagination as Pagination).then((data) =>
      data.results.map((simsn) => ({
        label: simsn.code,
        value: `${simsn.code}=`,
      })),
    );
  };
}

/*************************************** OPERATORS DONORS *********************** */
export const getOperatorsDonors = (
  params: Pagination,
): Promise<PaginateList<{ operator_code: string; operator_donor: string; id: string }>> => {
  return HTTP.get(`${endpoints.OPD.LIST}`, {
    params,
  }).then(({ data }) => data);
};

export function SearchOperatorsDonors(filter?: { [key: string]: any }) {
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getOperatorsDonors(pagination as Pagination).then((data) =>
      data.results.map((opd) => ({
        label: `${opd.operator_donor}`,
        value: opd.operator_code,
      })),
    );
  };
}

/*************************************** CUSTOMERS IPS *********************** */
export const getCustomerIps = (customerName: string, params: Pagination): Promise<{ ip_hosts: string[] }> => {
  return HTTP.get(`${endpoints.CUSTOMERS.INFO(customerName)}`, {
    params,
  }).then(({ data }) => data);
};

export function SearchCustomerIps(filter: { [key: string]: any }) {
  const customer_name = filter.customer_name;
  delete filter.customer_name;
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getCustomerIps(customer_name, pagination as Pagination).then((data) =>
      data.ip_hosts.map((ipHost) => ({
        label: `${ipHost}`,
        value: ipHost,
      })),
    );
  };
}
