import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Form from 'antd/es/form';
import { useState } from 'react';
import { useAppDispatch } from '../../store/store';
import { doLogin } from '../../store/slices/authSlice';
import Button from '../UI/Button/Button';
import { PATHS } from '../../routes/menu';
import { LoginData, LoginSchema } from '../../services/validations/validateLoginForm';
import { FormOptions } from '../../utils';
import FormItem from '../UI/fields/FormItem/FormItem';
import Input from 'antd/es/input/Password';

function FormLogin() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>(FormOptions(LoginSchema));

  const inputs = [
    {
      label: 'username',
      name: 'username',
      required: true,
    },
    {
      label: 'password',
      name: 'password',
      required: true,
      specificInput: Input,
      specificProps: {
        type: 'password',
      },
    },
  ];

  const onSubmit: SubmitHandler<LoginData> = (data) => {
    setIsLoading(true);
    dispatch(doLogin(data))
      .unwrap()
      .then(() => {
        navigate(PATHS.FINALUSERS.LIST.LINK);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      className="my-16 flex size-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start"
    >
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700">{t('auth.login.form.title')}</h4>

        {inputs.map((input) => (
          <FormItem key={input.name} input={input} errors={errors} control={control} />
        ))}

        <Button htmlType="submit" data-cy="submiter" isLoading={isLoading} className="w-full" type="primary">
          {t('auth.login.form.button')}
        </Button>
      </div>
    </Form>
  );
}

export default FormLogin;
