export const CHECKBOXES = ['is_virtual_trunk', 'live', 'partial', 'complexe', 'reliability'];
export const PORTA_INPUTS = [
  'live',
  'partial',
  'complexe',
  'porta_date',
  'rio',
  'rio_op_code',
  'reliability',
  'porta_time',
  'opd',
];
export const INDIVIDUAL_INPUTS = ['last_name', 'first_name', 'date_of_birth'];
export const COMMPANY_INPUTS = ['company_name', 'corporate_name'];
export const COMPANY_ID_INPUT_PER_COUNTRY: {
  [key: string]: string[];
} = {
  France: ['siret', 'naf', 'siret_code', 'naf_code'],
};

export const USER_INPUTS = (isCompany: boolean, selectedCountry: string | null) => [
  'select_user',
  ...(isCompany ? COMMPANY_INPUTS : INDIVIDUAL_INPUTS),
  ...(COMPANY_ID_INPUT_PER_COUNTRY[selectedCountry || ''] || []),
  'city',
  'email',
  'post_code',
  'postal_code',
  'postal_address',
];

export const ALL_USER_INPUTS = [
  ...INDIVIDUAL_INPUTS,
  ...USER_INPUTS(true, null),
  ...COMPANY_ID_INPUT_PER_COUNTRY.France,
];
