import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Space from 'antd/es/space';
import ControlledInput from '../../../ControlledInput/ControlledInput';
import ErrorDisplayer from '../../../errors/ErrorInputDisplayer';
import { Control, FieldErrors } from 'react-hook-form';
import Title from 'antd/es/typography/Title';
import RequiredFieldIndicator from '../../RequiredFieldIndicator/RequiredFieldIndicator';
import { useTranslation } from 'react-i18next';

type props = {
  input: any;
  errors: FieldErrors<any>;
  control: Control<any>;
};

export default function FormItem({ input, errors, control }: props) {
  const { t } = useTranslation();
  return (
    <Form.Item
      hidden={input.hidden}
      label={
        input.label && (
          <Space size="small" className="-mb-2 p-0">
            <Title className="!text-navy-700/70" level={5}>
              {t(`form.inputs.${input.label}.label`)}
            </Title>
            {input.required && <RequiredFieldIndicator />}
          </Space>
        )
      }
      tooltip={t(`form.inputs.${input.label}.info`, { defaultValue: false })}
    >
      <ControlledInput
        name={input.name}
        controledInput={input.specificInput ?? Input}
        errors={errors}
        control={control}
        specificProps={input.specificProps}
      />
      <ErrorDisplayer name={input.name} errors={errors} />
    </Form.Item>
  );
}
