import { createSlice } from '@reduxjs/toolkit';

interface IsidebarState {
  expand: boolean;
}

const initialState: IsidebarState = {
  expand: false,
};

export const sidebarSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.expand = !state.expand;
    },
    closeSidebar: (state) => {
      state.expand = false;
    },
  },
});

export default sidebarSlice.reducer;
export const { toggleSidebar, closeSidebar } = sidebarSlice.actions;
