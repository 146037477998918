export const endpoints = {
  AUTH: {
    LOGIN: 'auth/jwt/create/',
    REFRESH_TOKEN: 'auth/jwt/refresh/',
  },
  FINAL_USERS: {
    LIST: 'final_users/',
    ELEMENT: (userCode: string) => `final_users/${userCode}/`,
    START_VALIDATATION: (userCode: string) => `final_users/${userCode}/start_identity_verification/`,
  },
  USERS: {
    LIST: 'users/',
  },
  PHONE_NUMBERS: {
    LIST: 'phone_numbers/',
    SLICES: {
      LIST: 'number_slices/',
      DECLARE: 'number_slices/host_phone_numbers_slice/',
      MAKE_AVAILABLE: '/number_slices/make_phone_numbers_available/',
    },
    ELEMENT: (id: number) => `phone_numbers/${id}/`,
    ELEMENT_ATTRIBUTES: '/phone_numbers/attribute/',
    ELEMENT_ATTRIBUTES_CONFIRM: '/phone_numbers/attribute_confirm/',
  },
  SDA: {
    LIST: 'requests/sda/',
    ELEMENT: (request_id: number) => `requests/sda/${request_id}/`,
    IMPORT: '',
    BULK: {
      CREATE: 'requests/sda/bulk_create/',
      DELETE: 'requests/sda/bulk_delete/',
      UPDATE: 'requests/sda/bulk_update/',
      REGISTER: 'requests/sda/bulk_register/',
      IMPORT: 'requests/sda/bulk_create_from_excel/',
    },
  },
  MVN: {
    LIST: 'requests/mobiles/mvn/',
    ELEMENT: (mvn_id: number) => `requests/mobiles/mvn/${mvn_id}/`,
    BULK: {
      CREATE: 'requests/mobiles/mvn/bulk_create/',
      UPDATE: 'requests/mobiles/mvn/bulk_update/',
      DELETE: 'requests/mobiles/mvn/bulk_delete/',
    },
  },
  SIM: {
    LIST: 'requests/mobiles/sims/',
    ELEMENT: (sim_id: number) => `requests/mobiles/sims/${sim_id}/`,
    BULK: {
      CREATE: 'requests/mobiles/sims/bulk_create/',
      UPDATE: 'requests/mobiles/sims/bulk_update/',
      DELETE: 'requests/mobiles/sims/bulk_delete/',
      REGISTER: 'requests/mobiles/sims/bulk_register/',
      SUSPENDRESUME: 'requests/mobiles/sims/bulk_suspend_or_resume/',
      SWITCH: 'requests/mobiles/sims/bulk_switch/',
      USAGE: 'requests/mobiles/sims/bulk_usage/',
    },
  },
  MOBILE_FIRST: {
    LIST: 'requests/mobiles/first/',
    ELEMENT: (sim_id: number) => `requests/mobiles/first/${sim_id}/`,
    BULK: {
      CREATE: 'requests/mobiles/first/bulk_create/',
      UPDATE: 'requests/mobiles/first/bulk_update/',
      DELETE: 'requests/mobiles/first/bulk_delete/',
      REGISTER: 'requests/mobiles/first/bulk_register/',
      SUSPENDRESUME: 'requests/mobiles/first/bulk_suspend_or_resume/',
      SWITCH: 'requests/mobiles/first/bulk_switch/',
      USAGE: 'requests/mobiles/first/bulk_usage/',
    },
  },
  EXTRA_MOBILE: {
    SIMSN: {
      LIST: 'mobile_sims/',
    },
    SERVICES: {
      LIST: 'mobiles_services/',
    },
    PARAMS: {
      LIST: 'mobiles_services_params/',
    },
  },
  OPD: {
    LIST: 'porta_donors/',
  },
  CUSTOMERS: {
    INFO: (customer_name: string) => `customers/info/${customer_name}/`,
  },
  TRUNK: {
    LIST: 'requests/trunk/',
    ELEMENT: (sim_id: number) => `requests/trunk/${sim_id}/`,
    BULK: {
      CREATE: 'requests/trunk/bulk_create/',
      UPDATE: 'requests/trunk/bulk_update/',
      DELETE: 'requests/trunk/bulk_delete/',
    },
  },
  TRUNK_PORTA: {
    LIST: 'portability_trunk/',
    CANCEL: 'portability_trunk/cancel/',
    POST_PONE: 'portability_trunk/postpone/',
    REQUEST_PORTA: 'phone_numbers/portability_create/',
  },
  UPLOADS: {
    LIST: 'uploads/',
    ELEMENT: (id: number) => `uploads/${id}/`,
  },
  COUNTRIES: {
    LIST: 'countries/',
    ELEMENT: (id: number) => `countries/${id}/`,
  },
  CITIES_POSTAL_CODES: {
    LIST: 'cities_postal_codes/',
    ELEMENT: (id: number) => `countries/${id}/`,
  },
};
