import { endpoints } from '../constants/endpoints';
import { ICity, ICountry, setCountries } from '../store/slices/prefetchDataSlice';
import { store } from '../store/store';
import { HTTP } from './httpServices';

export const getCitiesPostalCodes = (params: Pagination): Promise<PaginateList<ICity>> => {
  return HTTP.get(`${endpoints.CITIES_POSTAL_CODES.LIST}`, {
    params,
  }).then(({ data }) => data);
};

export function SearchCitiesPostalCodes(
  label: keyof ICity,
  value: keyof ICity,
  filter?: {
    [key: string]: any;
  },
) {
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getCitiesPostalCodes(pagination as Pagination).then((data) =>
      data.results.map((city) => ({
        label: city[label],
        value: city[value],
      })),
    );
  };
}

export const getCountries = (params: Pagination): Promise<PaginateList<ICountry>> => {
  return HTTP.get(`${endpoints.COUNTRIES.LIST}`, {
    params,
  }).then(({ data }) => {
    return data;
  });
};

export function SearchCountries(
  label: keyof ICountry,
  value: keyof ICountry,
  filter?: {
    [key: string]: any;
  },
) {
  return async (query: string): Promise<any[]> => {
    const pagination: Omit<Pagination, 'limit' | 'offset'> = {
      search: query,
      ...filter,
    };
    return getCountries(pagination as Pagination).then((data) => {
      store.dispatch(setCountries(data.results));
      return data.results.map((country) => ({
        label: country[label],
        value: country[value],
      }));
    });
  };
}
