import React from 'react';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

export const Dropdown = (props: {
  button: JSX.Element;
  children: JSX.Element;
  classNames: string;
  animation?: string;
}) => {
  const { button, children, classNames, animation } = props;
  const wrapperRef = React.useRef(null);
  const [openWrapper, setOpenWrapper] = React.useState(false);

  useOnClickOutside(wrapperRef, () => setOpenWrapper(false));

  return (
    <div ref={wrapperRef} className="relative flex">
      <div className="flex" onMouseDown={() => setOpenWrapper(!openWrapper)}>
        {button}
      </div>
      <div
        className={`${classNames} absolute z-10 ${
          animation ? animation : 'origin-top-right transition-all duration-300 ease-in-out'
        } ${openWrapper ? 'scale-100' : 'scale-0'}`}
      >
        {children}
      </div>
    </div>
  );
};
